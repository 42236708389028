import { Flex } from '@chakra-ui/react';
import ApryseBlock from 'pages/sheet/components/apryse-block';

const ProjectDrawings = () => {
  return (
    <Flex direction='column' pos='relative' h='100%'>
      <ApryseBlock
        wrapperProps={{ p: 0, borderRadius: 0, h: 'inherit' }}
        blueprintStyles={{ borderRadius: 0 }}
        webviewerStyles={{ borderRadius: 0 }}
      />
    </Flex>
  );
};

export default ProjectDrawings;
