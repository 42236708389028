import { Checkbox, CheckboxProps } from '@chakra-ui/react';
import { useFormContext } from 'react-hook-form';

interface CheckboxRHFProps extends CheckboxProps {
  name: string;
}

const CheckboxRHF = ({ name, ...rest }: CheckboxRHFProps) => {
  const { register } = useFormContext();

  return <Checkbox {...register(name)} {...rest} />;
};

export default CheckboxRHF;
