const TileIcon = ({ size = '20px', fill = '#6E6B7B' }) => {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
    >
      <path
        fill={fill}
        d='M2.5 2.5h6.667v6.667H2.5V2.5zm0 8.333h6.667V17.5H2.5v-6.667zM10.833 2.5H17.5v6.667h-6.667V2.5zm0 8.333H17.5V17.5h-6.667v-6.667z'
      ></path>
    </svg>
  );
};

export default TileIcon;
