export enum ERoutes {
  PROJECTS = '/projects',
  ID = '/:id',
  ADD_PROJECT = '/add-project',
  SHEET = '/sheet',
  SHEET_ID = '/:sheetId',
  NAME = '/:name',
  DRAWINGS = '/drawings',
  DRAWINGS_TYPE = '/:drawingsType',
  DRAWING_ID = '/:drawingId',
  REPORTS = '/reports',
  REPORT_ID = '/:reportId',
  NEW = '/new',
  SOURCES = '/sources',
  SOURCES_ID = '/:sourcesId',
  _3D_MODELS = '/3d-models',
  CATEGORIES = '/categories',
  FEEDERS = '/feeders',
  PANELS = '/panels',
  CONNECTIONS = '/panel-connections',
}
