import { ReactNode } from 'react';

export enum WORK_MODES {
  BRANCH = 'Branch',
  FEEDERS = 'Feeders',
}

export interface ApplicationState {
  dirty: boolean;
  selectedTool: string;
  areAnnotationsDetected: boolean;
  isDocumentLoaded: boolean;
  workMode: WORK_MODES;
}

export interface BranchTabState {
  isOpen: boolean;
  activeTabIndex: number;
  isTabModalOpen: boolean;
}

export interface SLDElement {
  id: string;
  type: string;
  class?: string;
  level?: string;
  tag?: string;
  inputElement?: string;
  outputElement?: string;
  connectionTag?: string;
}

export interface SLDFeederElement {
  id: string;
  backendId: number;
  type: string;
  tag: string;
  colourCode: string;
  drawingId?: number;
}

export interface SLDPanelElement {
  id: string;
  backendId: number;
  type: string;
  panelClass: string;
  level?: string;
  tag: string;
  colourCode: string;
  drawingId?: number;
}

export interface SLDConnection {
  id: number;
  panelOne: SLDPanelElement;
  panelTwo: SLDPanelElement;
  feeder: SLDFeederElement;
}

export interface SLDData {
  sldPanels: SLDPanelElement[];
  sldFeeders: SLDFeederElement[];
  connectionList: SLDConnection[];
}

export interface SLDState {
  isModalOpen: boolean;
  sldList: { [documentId: number]: SLDData };
}

export interface Document {
  id: number;
  name: string;
  fileExtension: string;
  isReady: boolean;
  isProcessing: boolean;
  isSaved: boolean;
  currentPage: number;
  pagesCount: number;
  areAnnotationsLoaded: boolean;
  preview: string;
  lastModified: number;
  size: number;
  type: string;
  scale: string | null;
}

export interface RedlineFormState {
  grouping_distance: number;
  cable_count: number;
  grouping_reward: number;
  turning_penalty: number;
  iterations: number;
  preferred_zones_reward: number;
  width: number;
  height: number;
  pixels_per_ft: number;
  conduits_spacing: number;
}

export interface DocumentsState {
  openedDocuments: { [id: number]: Document };
  uploadDocuments: { [id: number]: Document };
}

export interface Option {
  id: number | string;
  label: ReactNode;
  value: string | number;
}

export interface ColumnData {
  id: string;
  label: string;
  defaultValue?: string;
  readonly: boolean;
  inputType?: string;
  options?: Option[];
  isHidden?: boolean;
  hiddenAIB?: boolean;
}

export interface EditColumnData {
  id: string;
  label?: string;
  defaultValue?: string;
  readonly?: boolean;
  inputType?: string;
  options?: Option[];
}

export interface LayerData {
  layerName: string;
  documentId: string;
}

export interface EditLayerData {
  layerName: string;
  documentId: string;
}

export enum AnnotationTypes {
  Polyline,
  Rectangle,
  Arc,
  Callout,
  Line,
}

export enum ElementTypes {
  HOMERUN_BOX,
  CONDUIT,
  MC_CABLE,
  RECEPTACLE,
  DOOR,
  WALL,
  AREA,
  GRID,
  TEXT,
  ERROR,
}

export interface Annotation {
  id: string;
  // TODO: convert type to Enum
  type: string; // Polyline, Rectangle
  // TODO: convert type to Enum
  elementType: string; // HOMERUN_BOX, CONDUIT, MC_CABLE
  _isLocked: boolean;
  _isVisible: boolean;
  isReadOnly: boolean;
  pathPoints?: number[][];
  // TODO: add rest annotation properties

  data: {
    [columnId: string]: any;
  };
}

export interface ColumnsState {
  allColumns: { [columnId: ColumnData['id']]: ColumnData };
  nonExportableColumnsIds: string[];
  columnsOrder: string[];
}

export interface AnnotationsState {
  selectedAnnotations: { [documentId: number]: Annotation[] };
  isAnnotationsListOpen: boolean;
  isAnnotationDetailsOpen: boolean;
  annotations: { [documentId: number]: Annotation[] };
}

export interface Layer {
  id: number;
  name: string;
  visible: boolean;
  annotations: Annotation[];
}

export interface LayersState {
  activeLayer: {
    layerId: number;
    layerName: string;
  } | null;
  layerList: {
    [documentId: number]: {
      [layerId: number]: Layer;
    };
  };
}

export interface MockedProjectsState {
  [key: string]: string;
}

export interface RTKMutation {
  requestId: string;
  status: string;
  endpointName: string;
  startedTimeStamp: number;
  error: {
    status: string;
    error: string;
  };
}

export interface RTKQuery {
  status: string;
  endpointName: string;
  requestId: string;
  originalArgs: unknown;
  startedTimeStamp: number;
  error: {
    status: string;
    error: string;
  };
}

export interface DrawerApiState {
  queries: { [rtkQueryId: string]: RTKQuery };
  mutations: { [rtkMutationId: string]: RTKMutation };
  provided: unknown;
  subscriptions: unknown;
  config: {
    online: boolean;
    focused: boolean;
    middlewareRegistered: boolean;
    refetchOnFocus: boolean;
    refetchOnReconnect: boolean;
    refetchOnMountOrArgChange: boolean;
    keepUnusedDataFor: number;
    reducerPath: string;
  };
}

export interface ModalState {
  data?: Record<string, unknown>;
}

export interface State {
  drawerApi: DrawerApiState;
  documents: DocumentsState;
  annotations: AnnotationsState;
  layers: LayersState;
  columns: ColumnsState;
  modals: ModalState;
  application: ApplicationState;
  mockedProjects: MockedProjectsState;
  redlineForm: RedlineFormState;
  branchTab: BranchTabState;
  sld: SLDState;
}
