import {
  Avatar,
  AvatarBadge,
  Box,
  Button,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Text,
} from '@chakra-ui/react';
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AuthContext, IAuthContext } from 'auth-service/index.ts';
import React, { useContext } from 'react';

/* @ts-ignore */
import avatar from '../../assets/images/Den-Lavrik.jpg';
import { styles } from './styles';

const ProfileMenu = () => {
  const { logOut }: IAuthContext = useContext(AuthContext);
  return (
    <Flex ml='15px'>
      <Menu>
        <MenuButton as={Flex}>
          <Box sx={{ display: 'flex' }}>
            <Box sx={styles.userInfoWrapper}>
              <Text sx={styles.userNameText}>Den Lavrik</Text>
              <Text sx={styles.userRoleText}>Admin</Text>
            </Box>
            <Avatar sx={styles.avatar} src={avatar}>
              <AvatarBadge sx={styles.badge} />
            </Avatar>
          </Box>
        </MenuButton>
        <MenuList p='0px' minWidth='150px'>
          <Button onClick={() => logOut()} width='100%' fontSize='12px'>
            Logout
          </Button>
        </MenuList>
      </Menu>
    </Flex>
  );
};

export default ProfileMenu;
