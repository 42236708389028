import { BUTTON_ICONS, BUTTON_NAMES, TOOL_NAMES, TOOLTIPS } from 'constants/apryse';

export const DEFAULT_SCALE = {
  SCALE_RATIO: {
    pageScale: { value: 1, unit: 'in' },
    worldScale: { value: 1, unit: 'in' },
  },
  PRECISION: 0.01,
};

export const POLYLINE_MIN_LENGTH = 2;

export const TOOLBAR_GROUP = {
  INSERT: 'toolbarGroup-Insert',
  MEASURE: 'toolbarGroup-Measure',
  SHAPES: 'toolbarGroup-Shapes',
  ANNOTATE: 'toolbarGroup-Annotate',
  FILL_AND_SIGN: 'toolbarGroup-FillAndSign',
  EDIT: 'toolbarGroup-Edit',
  FORMS: 'toolbarGroup-Forms',
  VIEW: 'toolbarGroup-View',
  LEFT_PANEL: 'leftPanel',
  LEFT_PANEL_BUTTON: 'leftPanelButton',
  VIEW_CONTROLS: 'viewControls',
  OUTLINES_PANEL: 'outlinesPanel',
  VIEW_CONTROLS_BUTTON: 'viewControlsButton',
  MENU_BUTTON: 'menuButton',
};

export const HIDDEN_TOOLS = {
  INSERT: ['rubberStampToolGroupButton', 'signatureToolGroupButton', 'threeDToolGroupButton'],
  ANNOTATE: [
    'shapeToolGroupButton',
    'freeHandHighlightToolGroupButton',
    'freeHandToolGroupButton',
    'stickyToolGroupButton',
    'squigglyToolGroupButton',
    'strikeoutToolGroupButton',
  ],
  SHAPES: [
    'freeHandHighlightToolGroupButton',
    'freeHandToolGroupButton',
    'arrowToolGroupButton',
    'polygonCloudToolGroupButton',
  ],
};

export const TOOL_BUTTON = 'toolButton';

export const POLYGON_TOOL_GROUP_BUTTON = 'polygonToolGroupButton';

export enum ZONES {
  NO_FLY_ZONE = 'NO_FLY_ZONE',
  PREFERRED_ZONE = 'PREFERRED_ZONE',
}

export enum PANELS {
  CRITICAL = 'CRITICAL',
  CRITICAL_HORIZONTAL = 'CRITICAL_HORIZONTAL',
  NORMAL_HORIZONTAL = 'NORMAL_HORIZONTAL',
  NORMAL = 'NORMAL',
}

export const PANEL_TYPE_DATA: any = {
  [PANELS.CRITICAL]: {
    toolName: TOOL_NAMES.CRITICAL_PANEL,
    tooltip: TOOLTIPS.CRITICAL_PANEL,
    buttonName: BUTTON_NAMES.CRITICAL_PANEL,
    buttonImage: BUTTON_ICONS.CRITICAL_PANEL,
  },
  [PANELS.CRITICAL_HORIZONTAL]: {
    toolName: TOOL_NAMES.CRITICAL_PANEL_HORIZONTAL,
    tooltip: TOOLTIPS.CRITICAL_PANEL_HORIZONTAL,
    buttonName: BUTTON_NAMES.CRITICAL_PANEL_HORIZONTAL,
    buttonImage: BUTTON_ICONS.CRITICAL_PANEL,
  },
  [PANELS.NORMAL]: {
    toolName: TOOL_NAMES.NORMAL_PANEL,
    tooltip: TOOLTIPS.NORMAL_PANEL,
    buttonName: BUTTON_NAMES.NORMAL_PANEL,
    buttonImage: BUTTON_ICONS.NORMAL_PANEL,
  },
  [PANELS.NORMAL_HORIZONTAL]: {
    toolName: TOOL_NAMES.NORMAL_PANEL_HORIZONTAL,
    tooltip: TOOLTIPS.NORMAL_PANEL_HORIZONTAL,
    buttonName: BUTTON_NAMES.NORMAL_PANEL_HORIZONTAL,
    buttonImage: BUTTON_ICONS.NORMAL_PANEL,
  },
};

export const ZONES_NAME = {
  [ZONES.NO_FLY_ZONE]: TOOL_NAMES.NO_FLY_ZONE,
  [ZONES.PREFERRED_ZONE]: TOOL_NAMES.PREFERRED_ZONE,
};

export const APRYSE_DOC_DIMENSIONS = {
  WIDTH: 3030,
  HEIGHT: 2160,
};

export const MARQUEE_ZOOM_TOOL_IMAGE =
  'data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PGRlZnM+PHN0eWxlPi5jbHMtMXtmaWxsOiNhYmIwYzQ7fTwvc3R5bGU+PC9kZWZzPjx0aXRsZT5pY29uIC0gaGVhZGVyIC0gem9vbSAtIG1hcnF1ZWU8L3RpdGxlPjxwYXRoIGNsYXNzPSJjbHMtMSIgZD0iTTMuMDUsMTguODRINi4yMXYtMi4xSDQuMTFWMTQuNjNIMnYzLjE2QTEsMSwwLDAsMCwzLjA1LDE4Ljg0Wk0xOC44NCwzLjA1YTEsMSwwLDAsMC0xLjA1LTFIMTQuNjNWNC4xMWgyLjExdjIuMWgyLjFaTTQuMTEsNC4xMWgyLjFWMkgzLjA1YTEsMSwwLDAsMC0xLDFWNi4yMUg0LjExWk0yLDguMzJINC4xMXY0LjIxSDJabTE0Ljc0LDBoMi4xdjMuMTVoLTIuMVpNMjIsMjFsLTEuODEtMS44MmEzLjUzLDMuNTMsMCwwLDAsLjU5LTEuOTUsMy42NCwzLjY0LDAsMSwwLTEuNjUsM0wyMSwyMlptLTYuOS0zLjc3YTIuMDgsMi4wOCwwLDEsMSwyLjA4LDIuMDhBMi4wOCwyLjA4LDAsMCwxLDE1LjEsMTcuMThaTTguMzIsMmg0LjIxVjQuMTFIOC4zMlptMCwxNC43NGgzLjE1djIuMUg4LjMyWiIvPjwvc3ZnPg==';

export const APRYSE_COLORS = {
  STROKE: {
    MC_CABLE: 'rgba(3, 48, 252, 1)',
    CONDUIT: 'rgba(139, 0, 0, 1)',
  },
};
