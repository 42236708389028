import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

interface DrawPolylineProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawPolyline = ({ viewerInstance, annotations, redraw }: DrawPolylineProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationStrokeColor = extractRGB(annotation.data.strokeColor || '');
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.RectangleAnnotation;
      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }
      return annotationToUpdate;
    });

    annotationsToRedraw.forEach((annotationToRedraw) => {
      annotationManager.redrawAnnotation(annotationToRedraw as Core.Annotations.Annotation);
    });
  } else {
    const annotationsToDraw = annotations.map((annotation) => {
      const annotationStrokeColor = extractRGB(annotation.data.strokeColor || '');
      const polylineAnnotation = new viewerInstance.Core.Annotations.PolylineAnnotation({
        Id: annotation.id,
        PageNumber: annotation.pageNumber,
        ...(annotationStrokeColor && {
          StrokeColor: new viewerInstance.Core.Annotations.Color(
            annotationStrokeColor[0] as number,
            annotationStrokeColor[1] as number,
            annotationStrokeColor[2] as number,
            annotationStrokeColor[3] as number,
          ),
        }),
        Style: annotation?.style,
        Dashes: annotation?.dashes,
        Hidden: !annotation?._isVisible,
        Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
      });

      annotation.pathPoints?.forEach((pathPoint: number[]) => {
        polylineAnnotation.addPathPoint(pathPoint[0], pathPoint[1]);
      });
      return polylineAnnotation;
    });
    annotationManager.addAnnotations(annotationsToDraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
