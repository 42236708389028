import { Icon } from '@chakra-ui/react';
import React from 'react';

interface IDeleteIcon {
  onClick?: VoidFunction;
  size: string;
}

const DeleteIcon = ({ onClick, size, ...rest }: IDeleteIcon) => {
  return (
    <Icon
      xmlns='http://www.w3.org/2000/svg'
      width={size}
      height={size}
      fill='none'
      viewBox='0 0 20 20'
      onClick={onClick}
      cursor='pointer'
      {...rest}
    >
      <path
        fill='#EA5455'
        d='M14.167 5h4.166v1.667h-1.666V17.5a.833.833 0 01-.834.833H4.167a.833.833 0 01-.834-.833V6.667H1.667V5h4.166V2.5a.833.833 0 01.834-.833h6.666a.833.833 0 01.834.833V5zM15 6.667H5v10h10v-10zm-3.822 5l1.474 1.473-1.179 1.178L10 12.845l-1.473 1.473-1.179-1.178 1.474-1.473-1.474-1.474 1.179-1.178L10 10.488l1.473-1.473 1.179 1.178-1.474 1.474zM7.5 3.333V5h5V3.333h-5z'
      ></path>
    </Icon>
  );
};

export default DeleteIcon;
