import { Core, WebViewerInstance } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';
import { ApryseAnnotation } from 'types';
import { extractRGB } from 'utils/rgb-hex';

interface drawCircuitProps {
  viewerInstance: WebViewerInstance;
  annotations: ApryseAnnotation[];
  redraw?: boolean;
}

export const drawCircuit = ({ viewerInstance, annotations, redraw = false }: drawCircuitProps) => {
  const annotationManager = viewerInstance.Core.annotationManager;

  if (redraw) {
    const annotationsToRedraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(annotation?.data?.fillColor || 'rgba(255, 0, 0, 0.1)');
      const annotationStrokeColor = extractRGB(
        annotation?.data?.strokeColor || 'rgba(255, 20, 0, 1)',
      );
      const id = annotation.id || annotation.Id;
      const annotationToUpdate = annotationManager.getAnnotationById(
        id,
      ) as unknown as Core.Annotations.RectangleAnnotation;
      if (typeof annotation.x !== 'undefined') annotationToUpdate.setX(annotation.x);
      if (typeof annotation.y !== 'undefined') annotationToUpdate.setY(annotation.y);
      if (typeof annotation.width !== 'undefined') annotationToUpdate.setWidth(annotation.width);
      if (typeof annotation.height !== 'undefined') annotationToUpdate.setHeight(annotation.height);
      // @ts-ignore
      annotationToUpdate.data = {
        // @ts-ignore
        ...annotationToUpdate.data,
        ...annotation.data,
      };
      if (annotationFillColor) {
        annotationToUpdate.FillColor = new viewerInstance.Core.Annotations.Color(
          annotationFillColor[0] as number,
          annotationFillColor[1] as number,
          annotationFillColor[2] as number,
          annotationFillColor[3] as number,
        );
      }
      if (annotationStrokeColor) {
        annotationToUpdate.StrokeColor = new viewerInstance.Core.Annotations.Color(
          annotationStrokeColor[0] as number,
          annotationStrokeColor[1] as number,
          annotationStrokeColor[2] as number,
          annotationStrokeColor[3] as number,
        );
      }
      return annotationToUpdate;
    });

    annotationsToRedraw.forEach((annotationToRedraw) => {
      annotationManager.redrawAnnotation(annotationToRedraw as Core.Annotations.Annotation);
    });
  } else {
    const annotationsToDraw = annotations.map((annotation) => {
      const annotationFillColor = extractRGB(annotation?.data?.fillColor || 'rgba(255, 0, 0, 0.1)');
      const annotationStrokeColor = extractRGB(
        annotation?.data?.strokeColor || 'rgba(255, 20, 0, 1)',
      );
      const CircuitAnnotation = viewerInstance.Core.Annotations.CustomAnnotation.createFromClass(
        ANNOTATION_TYPES.CIRCUIT,
        viewerInstance.Core.Annotations.RectangleAnnotation,
      );
      viewerInstance.Core.annotationManager.registerAnnotationType(
        CircuitAnnotation.prototype.elementName,
        CircuitAnnotation,
      );
      const circuitAnnotation = new CircuitAnnotation({
        Id: annotation.id || annotation.Id,
        PageNumber: annotation.pageNumber || annotation.PageNumber || 1,
        ...(annotationStrokeColor && {
          StrokeColor: new viewerInstance.Core.Annotations.Color(
            annotationStrokeColor[0] as number,
            annotationStrokeColor[1] as number,
            annotationStrokeColor[2] as number,
            annotationStrokeColor[3] as number,
          ),
        }),
        ...(annotationFillColor && {
          FillColor: new viewerInstance.Core.Annotations.Color(
            annotationFillColor[0] as number,
            annotationFillColor[1] as number,
            annotationFillColor[2] as number,
            annotationFillColor[3] as number,
          ),
        }),
        Style: annotation?.style,
        Dashes: annotation?.dashes,
        Author: viewerInstance?.Core.annotationManager.getCurrentUser(),
        X: annotation?.x || annotation.X,
        Y: annotation?.y || annotation.Y,
        Width: annotation?.width || annotation.Width,
        Height: annotation?.height || annotation.Height,
      });
      // @ts-ignore
      circuitAnnotation.data = annotation.data;
      return circuitAnnotation;
    });
    annotationManager.addAnnotations(annotationsToDraw);
    annotationManager.drawAnnotations({ pageNumber: 1 });
  }
};
