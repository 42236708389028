import { Box, Flex, Text } from '@chakra-ui/react';

import SLDInputs from './sld-inputs';
import { styles } from './styles';

const SLD = () => {
  return (
    <Flex p='8.25px 37.5px 15px'>
      <Box as='form' maxW='460px' w='full'>
        <SLDInputs />
      </Box>
      <Flex ml='26.25px' mt='24.75px' direction='column' sx={styles.instructions}>
        <Text>A customer will:</Text>
        <Box as='ul' ml='5.25px'>
          <Text as='li'>Select SLD elements using the UI PDF library </Text>
          <Text as='li'>Set connections between panels</Text>
          <Text as='li'>Add a panel properties (name, floor, type)</Text>
        </Box>
      </Flex>
    </Flex>
  );
};

export default SLD;
