import { TOOL_NAMES } from 'constants/apryse';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useContext, useEffect } from 'react';
import { WORK_MODES } from 'types/redux';

export const useToggleModeToolsListener = (workMode: WORK_MODES) => {
  const { instance } = useContext(WebviewerContext);

  useEffect(() => {
    if (instance) {
      if (workMode === WORK_MODES.FEEDERS) {
        instance.UI.enableTools([TOOL_NAMES.SLD_SELECTOR]);
      }
      if (workMode === WORK_MODES.BRANCH) {
        instance.UI.disableTools([TOOL_NAMES.SLD_SELECTOR]);
      }
    }
  }, [workMode, instance]);
};
