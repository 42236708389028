import { ANNOTATION_TYPES } from 'constants/apryse';

import { CreateHomerunBoxAnnotationParams } from './types';

export const createSLDSelectorTool = ({
  Annotations,
  Tools,
  documentViewer,
  annotationManager,
}: CreateHomerunBoxAnnotationParams) => {
  const SLDSelectorAnnotation = Annotations.CustomAnnotation.createFromClass(
    ANNOTATION_TYPES.SLD_SELECTOR,
    Annotations.RectangleAnnotation,
  );

  Annotations.setCustomDrawHandler(
    SLDSelectorAnnotation,
    function drawHandler(ctx, pageMatrix, rotation, options) {
      // @ts-ignore
      options.annotation.elementType = ANNOTATION_TYPES.SLD_SELECTOR;
      options.originalDraw(ctx, pageMatrix, rotation);
    },
  );

  // This code is responsible for annotation copying

  // annotationManager.registerAnnotationType(
  //   SLDSelectorAnnotation.prototype.elementName,
  //   SLDSelectorAnnotation,
  // );

  const SLDSelectorCreateTool = new Tools.GenericAnnotationCreateTool(
    documentViewer,
    SLDSelectorAnnotation,
  );

  const defaults = {
    StrokeColor: new Annotations.Color(255, 0, 255, 1),
    FillColor: new Annotations.Color(0, 0, 0, 0),
    Opacity: 1,
    StrokeThickness: 1,
  };

  SLDSelectorCreateTool.defaults = defaults;

  return SLDSelectorCreateTool;
};
