import { Box, Flex, Text } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from 'assets/icons';
import maskGroup from 'assets/images/mask-group.png';
import AnnotationSelector from 'components/annotation-selector';
import VisibilityToggler from 'components/visibility-toggler';
import dayjs from 'dayjs';
import { ColsDefaultValues, HeaderProps, Renderer, Row } from 'react-table';
import { ColumnData } from 'types/redux';
import { convertRGBtoHex, getAlphaValue, HEXToRGB } from 'utils/rgb-hex';

import ColorColumnFilter, { filterByColor } from './color-column-filter';
import DateColumnFilter from './date-column-filter';
import { styles } from './styles';
import VisibilityColumnFilter, { filterByVisibility } from './visibility-column-filter';

interface Props {
  value: any;
}

interface ReturnColumn {
  Header: Renderer<HeaderProps<ColsDefaultValues>> | undefined | (() => JSX.Element);
  accessor: string;
  Cell?: (props: Props) => JSX.Element;
  width?: number;
  minWidth?: number;
  filter?:
    | string
    | ((rows: Row<{ [key: string]: any }>[], id: string | string[], filterValue: string) => Row[]);
  Filter?: (...args: unknown[]) => JSX.Element;
  disableSortBy?: boolean;
  disableFilters?: boolean;
  sortType?: string;
}

export function getColumnEntitiesInReactTableFormat(
  columnEntities: ColumnData[],
): [ReturnColumn[], string[]] {
  const hiddenColumns: string[] = [];
  const columns = columnEntities.map((column) => {
    if (column.isHidden) {
      hiddenColumns.push(column.id);
    }
    if (column.inputType === 'date') {
      return {
        Header: column.label,
        accessor: column.id,

        Cell: (props: Props) => {
          return dayjs(props.value).format('DD MMMM YYYY');
        },
        filter: 'equals',
        Filter: DateColumnFilter as () => JSX.Element,
      };
    }

    if (column.inputType === 'color') {
      return {
        Header: column.label,
        accessor: column.id,
        filter: filterByColor,
        Filter: ColorColumnFilter as () => JSX.Element,
        Cell: (props: Props) => {
          const alphaValue =
            typeof props.value === 'string' ? getAlphaValue(props.value) : props.value.alpha;
          return (
            <Flex w='full' h='full' alignItems='center'>
              <Box sx={styles.colorWrapper} bg={`url(${maskGroup})`}>
                <Box
                  bg={
                    typeof props.value === 'string'
                      ? convertRGBtoHex(props.value)
                      : HEXToRGB(props.value.color)
                  }
                  sx={styles.colorWrapperLeft}
                ></Box>
                <Box
                  bg={
                    typeof props.value === 'string'
                      ? props.value
                      : HEXToRGB(props.value.color, props.value.alpha)
                  }
                  sx={styles.colorWrapperRight}
                ></Box>
              </Box>
              <Text ml='3px'>{convertRGBtoHex(props.value)}</Text>
              <Text ml='14px'>{alphaValue * 100}%</Text>
            </Flex>
          );
        },
      };
    }

    if (column.inputType === 'select') {
      return {
        Header: column.label,
        accessor: column.id,
        sortType: 'basic',
        Cell: (props: Props) => {
          // @ts-ignore
          return (
            column.options?.find((option) => option.value.toString() === props.value.toString())
              ?.label || props.value.toString()
          );
        },
      };
    }
    if (column.id === 'annotationId') {
      return {
        Header: () => <Flex flex={'auto'}>Select</Flex>,
        minWidth: 75,
        width: 75,
        accessor: column.id,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          // @ts-ignore
          return <AnnotationSelector id={props.row.original.annotationId} />;
        },
      };
    }
    if (column.id === '_isVisible') {
      return {
        Header: () => <Flex flex={'auto'}>Visibility</Flex>,
        minWidth: 90,
        width: 90,
        accessor: '_isVisible',
        Filter: VisibilityColumnFilter as () => JSX.Element,
        filter: filterByVisibility,
        sortType: 'basic',
        Cell: (props: any) => {
          // @ts-ignore
          return <VisibilityToggler id={props.row.original.annotationId} />;
        },
      };
    }
    if (column.id === 'void') {
      return {
        Header: () => <Flex flex={'auto'}></Flex>,
        minWidth: 45,
        width: 45,
        accessor: 'void',
        disableSortBy: true,
        disableFilters: true,
        Cell: () => {
          return <Flex />;
        },
      };
    }
    if (column.inputType === 'checkbox') {
      return {
        Header: () => <Flex flex={'auto'}>{column.label}</Flex>,
        minWidth: 75,
        width: 75,
        accessor: column.id,
        disableSortBy: true,
        disableFilters: true,
        Cell: (props: any) => {
          // @ts-ignore
          return props.value ? <CheckIcon fill='#7368F0' /> : <CloseIcon />;
        },
      };
    }
    return {
      Header: column.label,
      accessor: column.id,
    };
  }) as unknown as ReturnColumn[];

  return [columns, hiddenColumns];
}
