import { WEBKIT_SCROLLBAR_GRAY } from 'constants/styles';

export const styles = {
  projectsWrapper: {
    paddingLeft: '13.5px',
    paddingRight: '13.5px',
    flexDirection: 'column',
    paddingTop: '9px',
    paddingBottom: '9px',
    alignItems: 'center',
    width: '100%',
    display: 'flex',
  },
  addProjectBtn: {
    height: '100%',
    minHeight: '36px',
    marginLeft: '32px',
    fontSize: '10.5px',
    padding: '11.55px',
    paddingLeft: '9.75px',
    fontWeight: 500,
  },

  cardMenuBtn: {
    cursor: 'pointer',
    float: 'right',
    svg: {
      minWidth: '15px',
      minHeight: '15px',
    },
  },
  projectCardMenuList: {
    minWidth: '144.75px',
    borderRadius: '6px',
    border: 'none',
    padding: '12px 15px',
    boxShadow: '0px 4px 24px 0px #22292F26',
    display: 'flex',
    flexDirection: 'column',
  },
  projectCardMenuItem: {
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '15px',
    color: 'black.50',
    display: 'flex',
    alignItems: 'center',
    padding: 0,
    marginBottom: '15px',
    '&:last-child': {
      marginBottom: '0px',
    },
    '&:hover, &:active, &[data-active], &:focus': {
      background: 'transparent',
    },
    svg: {
      minWidth: '15px',
      minHeight: '15px',
      marginRight: '4.5px',
      marginTop: '-1px',
    },
  },
  divider: {
    height: '1px',
    width: '100%',
    alignSelf: 'center',
    background: '#F3F3F3',
    margin: '-4px 0px 16px 0px',
  },
  wrapperStyles: {
    width: '100%',
    height: '80vh',
    overflowX: 'hidden',
    marginTop: '9px',
  },
  infiniteScrollStyles: {
    width: '100%',
    display: 'grid',
    justifyContent: 'space-between',
    gap: '30px',
    overflowY: 'auto',
    overflowX: 'hidden',
    padding: '45px',
    height: '100%',
    gridTemplateColumns: 'repeat(auto-fill, minmax(195px, 195px))',
    ...WEBKIT_SCROLLBAR_GRAY,
  },
  loaderStyles: {
    position: 'absolute',
    width: 'inherit',
    height: '100%',
    background: 'gray',
    opacity: 0.5,
    display: 'flex',
    borderRadius: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
    top: 0,
  },
  theadWrapper: {
    backgroundColor: '#F4F2FF',
    th: {
      padding: '13px 0px 12px 0px',
      color: 'black.100',
      fontSize: ' 10.5px',
      fontWeight: 500,
      lineHeight: '15px',
      textTransform: 'unset',
      '&:nth-of-type(1)': {
        paddingLeft: '27px',
      },
      '&:last-child': {
        paddingRight: '29px',
      },
    },
  },
  infiniteScrollList: {
    width: '100%',
    overflowY: 'auto',
    overflowX: 'hidden',
    height: '100%',
    ...WEBKIT_SCROLLBAR_GRAY,
  },
};
