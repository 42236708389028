import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';
import { pxToRem } from 'utils';

export const styles = {
  instructions: {
    fontFamily: 'Poppins',
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '16.5px',
    color: 'gray.375',
    marginLeft: pxToRem(35),
    marginTop: pxToRem(33),
    flexDirection: 'column',
  },

  sldWrapper: {
    maxWidth: pxToRem(610),
    width: '100%',
    flexDirection: 'column',
  },
  scrollWrapper: {
    overflowY: 'auto',
    maxHeight: pxToRem(177),
    width: '100%',
    ...WEBKIT_SCROLLBAR_STYLES,
  },

  sldModal: {
    border: `${pxToRem(1)} solid #7368F0`,
    borderRadius: pxToRem(6),
    padding: pxToRem(12),
    width: pxToRem(246),
  },
  connectionModal: {
    border: `${pxToRem(1)} solid #7368F0`,
    borderRadius: pxToRem(6),
    padding: pxToRem(6),
    maxWidth: pxToRem(550),
  },
  connectionModalBtn: {
    fontSize: '12px',
  },
  connectionModalTitle: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: pxToRem(15),
  },
  inputBlockWrapper: {
    flexDirection: 'column',
  },
  subtitleText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(10),
    lineHeight: '223%',
    marginBottom: pxToRem(4),
  },
  formBtn: (isActive: boolean) => ({
    borderRadius: pxToRem(6),
    border: `${pxToRem(1)} solid #D4D1FF`,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: isActive ? '#EFEEFF' : '#FFFFFF',
    padding: `0 ${pxToRem(4)}`,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(10),
    height: pxToRem(32),
    margin: 0,
  }),
  formLevelBtn: (isActive: boolean) => ({
    background: isActive ? '#EFEEFF' : '#FFFFFF',
    borderRadius: pxToRem(6),
    border: `${pxToRem(1)} solid #D4D1FF`,
    padding: `0 ${pxToRem(4)}`,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(10),
    height: pxToRem(32),
    minWidth: pxToRem(28),
  }),
  colorBox: {
    borderRadius: pxToRem(2),
    background: '#7368F0',
    height: pxToRem(20),
    width: pxToRem(20),
  },
  colorCodeText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(10),
    lineHeight: '223%',
    color: '#24202D',
    marginLeft: pxToRem(4),
  },
  percentageText: {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: pxToRem(10),
    lineHeight: '223%',
    color: '#24202D',
    marginLeft: pxToRem(14),
  },
  select: {
    borderRadius: pxToRem(6),
    borderColor: '#7368F0',
    fontFamily: 'Poppins',
    fontSize: pxToRem(12),
    color: '#24202D',
    w: '100%',
  },
};
