import { ANNOTATION_TYPES, DOCUMENT_ID, ELEMENT_TYPES } from 'constants/apryse';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import {
  createPathPoints,
  processCircuitAnnotationBeforeDrawing,
} from 'pages/sheet/components/apryse-block/utils';
import { useCallback, useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveLayer, getAnnotations, getDocuments, getLayersInDocument } from 'redux/selectors';
import { annotationActions, layerActions, sldActions } from 'redux/slices';
import { Annotation, Layer, State } from 'types/redux';

import { serializeAnnotations } from '../utils/serializeAnnotations';
import { TCoordinates } from './useMouseCoordsEventListener';

interface CoordsGetters {
  getMouseMoveCoords: () => TCoordinates;
  getMouseClickCoords: () => TCoordinates;
}

export const useAnnotationChangeListener = (
  getMouseMoveCoords: CoordsGetters['getMouseMoveCoords'],
  getMouseClickCoords: CoordsGetters['getMouseClickCoords'],
) => {
  const dispatch = useDispatch();
  const fileEntities = useSelector(getDocuments);
  const annotationEntities = useSelector(getAnnotations);
  const layerEntities = useSelector<State, { [layerId: number]: Layer }>((state) =>
    getLayersInDocument(state, { documentId: DOCUMENT_ID }),
  );
  const activeLayer = useSelector(getActiveLayer);

  const { instance } = useContext(WebviewerContext);

  const isSameCoords =
    getMouseMoveCoords().x === getMouseClickCoords().x &&
    getMouseMoveCoords().y === getMouseClickCoords().y;

  const handler = useCallback(
    (annotations: any[], action: string) => {
      if (instance) {
        if (action === 'add') {
          const serializedAnnotations: Annotation[] = [];

          processCircuitAnnotationBeforeDrawing({
            activeLayer,
            annotationEntities,
            annotations,
            dispatch,
            fileEntities,
            isSameCoords,
            layerEntities,
            serializedAnnotations,
            viewerInstance: instance,
          });

          dispatch(
            annotationActions.addAnnotations({
              documentId: DOCUMENT_ID,
              annotations: serializedAnnotations,
            }),
          );

          if (activeLayer) {
            dispatch(
              layerActions.addAnnotationsToLayer({
                documentId: DOCUMENT_ID,
                layerId: activeLayer.layerId,
                annotations: serializedAnnotations.filter(
                  (value) => value.elementType !== ANNOTATION_TYPES.CIRCUIT,
                ),
              }),
            );
          }

          if (Object.values(layerEntities).find((layer) => layer.name === 'Circuits')) {
            dispatch(
              layerActions.addAnnotationsToLayer({
                documentId: DOCUMENT_ID,
                layerId: Object.values(layerEntities).find((layer) => layer.name === 'Circuits')
                  ?.id as number,
                annotations: serializedAnnotations.filter(
                  (value) => value.elementType === ANNOTATION_TYPES.CIRCUIT,
                ),
              }),
            );
          }
          if (annotations[0].elementType === ANNOTATION_TYPES.SLD_SELECTOR) {
            dispatch(sldActions.toggleModal({ isModalOpen: true }));
          }
        } else if (action === 'modify') {
          const serializedAnnotations = annotations.map((annotation) => {
            const pathPoints = createPathPoints({
              viewerInstance: instance,
              annotation,
              isSameCoords,
              isModify: true,
            });

            return serializeAnnotations({
              annotation,
              activeLayer,
              fileEntities,
              pathPoints,
              viewerInstance: instance,
              action: 'modify',
            });
          });
          dispatch(
            annotationActions.modifyAnnotations({
              documentId: DOCUMENT_ID,
              annotations: serializedAnnotations,
            }),
          );
        } else if (action === 'delete') {
          const serializedAnnotations = annotations.map((annotation) => {
            return serializeAnnotations({
              annotation,
              activeLayer,
              fileEntities,
              viewerInstance: instance,
            });
          });

          dispatch(
            annotationActions.deleteAnnotations({
              documentId: DOCUMENT_ID,
              annotations: serializedAnnotations,
            }),
          );

          annotations.forEach((annotation) => {
            Object.values(layerEntities).forEach((layer) => {
              if (
                layer.annotations.find((layerAnnotation) => layerAnnotation.id === annotation.Id)
              ) {
                dispatch(
                  layerActions.removeAnnotationFromLayer({
                    documentId: DOCUMENT_ID,
                    layerId: layer.id,
                    annotationIds: [annotation.Id],
                  }),
                );
              }
            });
          });
        }
      }
    },
    [
      activeLayer,
      annotationEntities,
      dispatch,
      fileEntities,
      isSameCoords,
      layerEntities,
      instance,
    ],
  );

  useEffect(() => {
    if (instance) {
      instance.Core.annotationManager.addEventListener('annotationChanged', handler);
    }

    return () => {
      if (instance) {
        instance.Core.annotationManager.removeEventListener('annotationChanged', handler);
      }
    };
  }, [handler, instance]);
};
