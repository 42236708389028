import { Box, ChakraProps, Flex, IconButton } from '@chakra-ui/react';
import WebViewer from '@pdftron/webviewer';
import { ZoomInLineIcon, ZoomOutLineIcon } from 'assets/icons';
import Card from 'components/card';
import { EUrlSearchParamKeys } from 'components/page-header/types';
import { WebviewerContext } from 'context/webviewer/webviewer-context';
import { useGetUrlSearchParams } from 'hooks';
import DrawingTabs from 'pages/projects/project/drawings/tabs';
import { ApryseLoader } from 'pages/sheet/components/apryse-block/apryse-loader';
import { useConnectPanelAndConduit } from 'pages/sheet/components/apryse-block/hooks/useConnectPanelAndConduit';
import { EViewMods } from 'pages/sheet/types';
import { useCallback, useContext, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAnnotations, getDocuments, getWorkMode, isDocumentLoaded } from 'redux/selectors';
import { documentActions, useImportJsonMutation } from 'redux/slices';
import { DropzoneFile, EFixedCachedKeys } from 'types';
import { WORK_MODES } from 'types/redux';
import { getDocumentPreviewUrls } from 'utils';

import {
  useAnnotationChangeListener,
  useAnnotationSelectedListener,
  useDeleteSelectedAnnotations,
  useDeselectAllAnnotations,
  useDocumentLoadedListener,
  useFinishPolylineOnEnterListener,
  useSetupPolylineListeners,
  useToggleModeToolsListener,
  useToolModeUpdatedListener,
  useToolUpdatedListener,
} from './hooks/index';
import { useMouseCoordsEventListener } from './hooks/useMouseCoordsEventListener';
import { styles } from './styles';

interface ApryseBlockProps {
  wrapperProps?: ChakraProps;
  blueprintStyles?: ChakraProps;
  webviewerStyles?: ChakraProps;
  file?: DropzoneFile | null;
}

const ApryseBlock = ({
  wrapperProps,
  blueprintStyles,
  webviewerStyles,
  file,
}: ApryseBlockProps) => {
  const viewer = useRef<HTMLElement | null>(null);
  const fileEntities = useSelector(getDocuments);
  const workMode = useSelector(getWorkMode);
  const annotationEntities = useSelector(getAnnotations);
  const isDocLoaded = useSelector(isDocumentLoaded);
  const { setInstanceContext, instance: webViewerInstance } = useContext(WebviewerContext);
  const { urlSearchParamsObject } = useGetUrlSearchParams();
  const [, { data: importJsonData, isError }] = useImportJsonMutation({
    fixedCacheKey: EFixedCachedKeys.IMPORT_JSON,
  });
  const enable3DView = !!importJsonData && !isError;
  const dispatch = useDispatch();
  const show3DView =
    urlSearchParamsObject[EUrlSearchParamKeys.VIEW_MODE] === EViewMods._3D && enable3DView;

  useConnectPanelAndConduit();

  const loadFile = useCallback(
    (file: string) => {
      if (webViewerInstance) {
        webViewerInstance.UI.loadDocument(file, {
          extension: 'pdf',
        });
      }
    },
    [webViewerInstance],
  );

  useEffect(() => {
    WebViewer(
      {
        path: '/webviewer',
        licenseKey: process.env.REACT_APP_APRYSE_LICENSE_KEY,
        enableMeasurement: true,
        fullAPI: false,
      },
      viewer.current as HTMLElement,
    ).then((webviewerInstance) => {
      setInstanceContext(webviewerInstance);
    });
  }, [setInstanceContext]);

  useEffect(() => {
    if (webViewerInstance && (!!file || (fileEntities && Object.keys(fileEntities).length))) {
      // get params from url(projectID, sheetID)
      // send request for backend
      // get data in pdf format
      // Temporary support only for 1 file
      if (file) {
        loadFile(file.preview);
      } else {
        const previews = getDocumentPreviewUrls(fileEntities);
        loadFile(previews[0]);
      }
    }
  }, [fileEntities, file, loadFile, webViewerInstance]);

  // Listeners | effects
  useToggleModeToolsListener(workMode);
  useAnnotationSelectedListener();
  useDocumentLoadedListener();
  useToolModeUpdatedListener();
  useToolUpdatedListener();
  useDeselectAllAnnotations();
  useFinishPolylineOnEnterListener();
  useDeleteSelectedAnnotations();

  const { getMouseCoords: getMouseMoveCoords, mouseEventHandler: mouseMoveEventHandler } =
    useMouseCoordsEventListener();
  const { getMouseCoords: getMouseClickCoords, mouseEventHandler: mouseClickEventHandler } =
    useMouseCoordsEventListener();

  useSetupPolylineListeners(mouseMoveEventHandler, mouseClickEventHandler);
  useAnnotationChangeListener(getMouseMoveCoords, getMouseClickCoords);

  const handleZoomIn = () => {
    if (webViewerInstance) {
      const {
        Core: { documentViewer },
      } = webViewerInstance;

      documentViewer.zoomTo(documentViewer.getZoomLevel() + 0.25);
    }
  };

  const handleZoomOut = () => {
    if (webViewerInstance) {
      const {
        Core: { documentViewer },
      } = webViewerInstance;

      const delta = documentViewer.getZoomLevel() - 0.25;
      const minZoomValue = webViewerInstance.UI.getMinZoomLevel();

      documentViewer.zoomTo(delta > minZoomValue ? delta : minZoomValue);
    }
  };

  useEffect(() => {
    return () => {
      dispatch(documentActions.resetDocuments());
    };
  }, [dispatch]);

  return (
    <>
      <Card
        wrapperProps={{
          width: '100%',
          height: '100%',
          position: 'relative',
          background: '#F1F3F5',
          border: `1px solid #7368F0`,
          ...wrapperProps,
        }}
      >
        <Flex sx={{ ...styles.blueprintWrapper(workMode), ...blueprintStyles }}>
          {show3DView && (
            <Box sx={styles.iframeWrapper}>
              <iframe
                src={importJsonData.link}
                width='100%'
                height='100%'
                title='Apryse Webviewer'
              />
            </Box>
          )}
          {Object.keys(annotationEntities).length ? (
            <Box
              className='webviewer'
              ref={viewer as React.LegacyRef<HTMLDivElement>}
              sx={{ ...styles.webviewer, ...webviewerStyles }}
            />
          ) : null}
          <Box sx={styles.zoomWrapper}>
            <IconButton
              icon={<ZoomInLineIcon size='12px' />}
              variant='ustyled'
              aria-label={''}
              mr='6px'
              onClick={handleZoomIn}
            />
            <IconButton
              icon={<ZoomOutLineIcon size='12px' />}
              variant='ustyled'
              aria-label={''}
              onClick={handleZoomOut}
            />
          </Box>
        </Flex>
        {workMode === WORK_MODES.FEEDERS ? (
          <DrawingTabs
            styleProps={{
              flexDirection: 'column',
              position: 'absolute',
              display: 'flex',
              bottom: '0',
              mt: '-39px',
              w: '100%',
            }}
          />
        ) : null}
        {isDocLoaded && <ApryseLoader />}
      </Card>
    </>
  );
};
export default ApryseBlock;
