export const styles = {
  sheetPageWrapper: {
    flexDirection: 'column',
    padding: '3px 13.5px 0px 13.5px',
    height: 'calc(100vh - 45px)',
    maxWidth: '100%',
    width: '100%',
    overflow: 'hidden',
  },
  headBlockInnerWrapper: {
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  leftHeadBlock: {
    alignItems: 'center',
  },
  headButton: {
    height: '36px',
    width: '36px',
    display: 'flex',
  },
  sheetNameText: {
    fontSize: '18px',
    lineHeight: '21px',
    color: 'gray.400',
    marginRight: '25.5px',
    fontWeight: 500,
  },
  toggleButton: {
    height: '36px',
    marginLeft: '6px',
    minWidth: 'unset',
    padding: '0px 9px',
    svg: {
      minWidth: '15px',
      minHeight: '15px',
    },
  },
  secondaryButton: {
    height: '36px',
    marginLeft: '6px',
    minWidth: 'unset',
    padding: '0px 9px',
    color: 'black.50',
    background: 'gray.25',
    fontSize: '10.5px',
  },
  mainBlockWrapper: {
    height: 'calc(100vh - 99px)',
    marginTop: '3px',
    width: '100%',
    position: 'relative',
  },
  apryseWrapper: {
    display: 'flex',
    width: '100%',
  },
  loaderWrapper: {
    position: 'absolute',
    width: 'inherit',
    height: '100%',
    background: 'gray',
    opacity: 0.5,
    display: 'flex',
    borderRadius: '6px',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 3,
  },
  functionalBlockWrapper: (
    isAnnotationListOpen: boolean,
    isTabOpen: boolean,
    activeTab: number,
  ) => ({
    width: !isAnnotationListOpen
      ? '100%'
      : isTabOpen && activeTab !== 8
      ? 'calc(100% - 295.5px)'
      : 'calc(100% - 54px)',
    position: 'relative',
  }),
  resizableTop: {
    marginTop: '9px',
    borderRadius: '6px',
    overflow: 'hidden',
  },
  resizableRight: (isAnnotationDetailsMenuOpen: boolean) => ({
    marginRight: isAnnotationDetailsMenuOpen ? '9px' : 0,
    overflow: 'hidden',
    borderRadius: '6px',
    transform: isAnnotationDetailsMenuOpen ? 'unset' : 'translateX(-100%)',
  }),
  resizableRightComponent: (isAnnotationDetailsMenuOpen: boolean) => ({
    borderRight: isAnnotationDetailsMenuOpen ? '3px solid #7367F0' : 'unset',
    width: isAnnotationDetailsMenuOpen ? '3px' : '0px',
    right: 0,
  }),
  resizableTopComponent: {
    top: 0,
  },
  drawingsWrapper: {
    position: 'absolute',
    zIndex: 20,
    width: '100%',
    height: '100%',
    padding: '13.5px',
    backdropFilter: 'blur(1px)',
  },
};
