import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import { authConfig } from 'auth';
// @ts-ignore
// eslint-disable-next-line import/extensions
import { AuthProvider } from 'auth-service/index.ts';
import { Fallback } from 'components/fallback';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import Layout from './components/layout';
import Router from './components/router';
import store from './redux/store';
import theme from './theme/theme';

const App = () => {
  return (
    <AuthProvider authConfig={authConfig}>
      <BrowserRouter>
        <Provider store={store}>
          <ChakraProvider theme={extendTheme({ ...theme })}>
            <ErrorBoundary FallbackComponent={Fallback}>
              <Layout>
                <Router />
              </Layout>
            </ErrorBoundary>
          </ChakraProvider>
        </Provider>
      </BrowserRouter>
    </AuthProvider>
  );
};

export default App;
