import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SLDConnection, SLDFeederElement, SLDPanelElement, SLDState } from 'types/redux';

export const sldSlice = createSlice({
  name: 'sld',
  initialState: {
    isModalOpen: false,
    sldList: {},
  },
  reducers: {
    toggleModal(
      state: SLDState,
      action: PayloadAction<{
        isModalOpen: boolean;
      }>,
    ) {
      state.isModalOpen = action.payload.isModalOpen;
    },
    addSLDFeeder(
      state: SLDState,
      action: PayloadAction<{
        documentId: number;
        sldFeeder: SLDFeederElement;
      }>,
    ) {
      if (typeof state.sldList[action.payload.documentId] === 'undefined') {
        state.sldList = Object.assign(state.sldList, {
          [action.payload.documentId]: { sldPanels: [], sldFeeders: [], connectionList: [] },
        });
        state.sldList[action.payload.documentId].sldFeeders.push(action.payload.sldFeeder);
      } else {
        state.sldList[action.payload.documentId].sldFeeders.push(action.payload.sldFeeder);
      }
    },
    addSLDPanel(
      state: SLDState,
      action: PayloadAction<{
        documentId: number;
        sldPanel: SLDPanelElement;
      }>,
    ) {
      if (typeof state.sldList[action.payload.documentId] === 'undefined') {
        state.sldList = Object.assign(state.sldList, {
          [action.payload.documentId]: { sldPanels: [], sldFeeders: [], connectionList: [] },
        });
        state.sldList[action.payload.documentId].sldPanels.push(action.payload.sldPanel);
      } else {
        state.sldList[action.payload.documentId].sldPanels.push(action.payload.sldPanel);
      }
    },
    addSLDConnection(
      state: SLDState,
      action: PayloadAction<{
        documentId: number;
        sldConnection: SLDConnection;
      }>,
    ) {
      if (typeof state.sldList[action.payload.documentId] === 'undefined') {
        state.sldList = Object.assign(state.sldList, {
          [action.payload.documentId]: { sldPanels: [], sldFeeders: [], connectionList: [] },
        });
        state.sldList[action.payload.documentId].connectionList.push(action.payload.sldConnection);
      } else {
        state.sldList[action.payload.documentId].connectionList.push(action.payload.sldConnection);
      }
    },
  },
});

export const sldActions = sldSlice.actions;
