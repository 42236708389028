import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ERoutes } from 'components/router/types';

import {
  MutationCreatePanelsConnectionParams,
  MutationSendFeederDataToServerParams,
  MutationSendFeederDataToServerResponse,
  MutationSendPanelDataToServerParams,
  MutationSendPanelDataToServerResponse,
} from './sld-api.types';

export const sldApiUrl = {
  FEEDERS: ({ projectId, drawingId }: { projectId: string; drawingId: string }) =>
    `${ERoutes.PROJECTS}/${projectId}${ERoutes.DRAWINGS}/${drawingId}${ERoutes.FEEDERS}`,
  PANELS: ({ projectId, drawingId }: { projectId: string; drawingId: string }) =>
    `${ERoutes.PROJECTS}/${projectId}${ERoutes.DRAWINGS}/${drawingId}${ERoutes.PANELS}`,
  CONNECTIONS: ({ projectId, drawingId }: { projectId: string; drawingId: string }) =>
    `${ERoutes.PROJECTS}/${projectId}${ERoutes.DRAWINGS}/${drawingId}${ERoutes.CONNECTIONS}`,
};

export const sldApiSlice = createApi({
  reducerPath: 'sldApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_PROJECTS_API,
    prepareHeaders: async (headers) => {
      const token = localStorage.getItem('ROCP_token');
      if (token) {
        headers.set('authorization', `Bearer ${token.replaceAll('"', '')}`);
      }
      return headers;
    },
  }),
  endpoints: (builder) => ({
    sendFeederDataToServer: builder.mutation<
      MutationSendFeederDataToServerResponse,
      MutationSendFeederDataToServerParams
    >({
      query: ({ projectId, drawingId, formData }) => ({
        url: sldApiUrl.FEEDERS({ drawingId, projectId }),
        method: 'POST',
        body: formData,
      }),
    }),
    sendPanelDataToServer: builder.mutation<
      MutationSendPanelDataToServerResponse,
      MutationSendPanelDataToServerParams
    >({
      query: ({ projectId, drawingId, formData }) => ({
        url: sldApiUrl.PANELS({ drawingId, projectId }),
        method: 'POST',
        body: formData,
      }),
    }),
    createPanelsConnection: builder.mutation<
      MutationSendPanelDataToServerResponse,
      MutationCreatePanelsConnectionParams
    >({
      query: ({ projectId, drawingId, formData }) => ({
        url: sldApiUrl.CONNECTIONS({ drawingId, projectId }),
        method: 'POST',
        body: formData,
      }),
    }),
  }),
});

export const {
  useSendFeederDataToServerMutation,
  useSendPanelDataToServerMutation,
  useCreatePanelsConnectionMutation,
} = sldApiSlice;
