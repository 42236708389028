import { Flex, Text } from '@chakra-ui/react';
import { useDispatch, useSelector } from 'react-redux';
import { getWorkMode } from 'redux/selectors';
import { annotationActions, applicationActions } from 'redux/slices';
import { WORK_MODES } from 'types/redux';

import { styles } from './styles';

const ModeSwitch = () => {
  const dispatch = useDispatch();
  const workMode = useSelector(getWorkMode);

  const handleSetBranchMode = () => {
    dispatch(applicationActions.setWorkMode({ workMode: WORK_MODES.BRANCH }));
  };

  const handleSetFeedersMode = () => {
    dispatch(applicationActions.setWorkMode({ workMode: WORK_MODES.FEEDERS }));
    dispatch(annotationActions.setAnnotationsListOpen({ isAnnotationsListOpen: false }));
  };

  return (
    <Flex sx={styles.customSwitch}>
      <Flex sx={styles.toggleItem(workMode === WORK_MODES.BRANCH)} onClick={handleSetBranchMode}>
        <Text>{WORK_MODES.BRANCH}</Text>
      </Flex>
      <Flex sx={styles.toggleItem(workMode === WORK_MODES.FEEDERS)} onClick={handleSetFeedersMode}>
        <Text>{WORK_MODES.FEEDERS}</Text>
      </Flex>
    </Flex>
  );
};

export default ModeSwitch;
