export const Checkbox = {
  baseStyle: {
    control: {
      w: '12px',
      h: '12px',
      border: '1px solid #6E6B7B',
      '&[data-focus]': {
        boxShadow: 'unset',
      },
      '&[data-focus-visible]': {
        boxShadow: 'unset',
      },
      svg: {
        width: '10px',
      },
      _checked: {
        background: 'purple.100',
        borderColor: 'purple.100',
        _hover: {
          background: 'purple.100',
        },
      },
    },
  },
};
