import { configureStore, createListenerMiddleware, isAnyOf } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/dist/query';

import {
  annotationActions,
  annotationSlice,
  applicationActions,
  applicationSlice,
  branchTabSlice,
  columnSlice,
  documentActions,
  documentSlice,
  drawerApiSlice,
  layerSlice,
  mockedProjectsSlice,
  modalSlice,
  projectsApiSlice,
  redlineFormSlice,
  sldApiSlice,
  sldSlice,
  view3DApiSlice,
} from '../slices';

const listenerMiddleware = createListenerMiddleware();

listenerMiddleware.startListening({
  matcher: isAnyOf(
    annotationActions.addAnnotations,
    annotationActions.selectAnnotation,
    documentActions.saveDocument,
  ),
  effect: (action, listenerApi) => {
    if (documentActions.saveDocument.match(action)) {
      listenerApi.dispatch(applicationActions.setDirty({ isDirty: false }));
    } else {
      listenerApi.dispatch(applicationActions.setDirty({ isDirty: true }));
    }
  },
});

const store = configureStore({
  reducer: {
    [drawerApiSlice.reducerPath]: drawerApiSlice.reducer,
    [sldApiSlice.reducerPath]: sldApiSlice.reducer,
    [view3DApiSlice.reducerPath]: view3DApiSlice.reducer,
    mockedProjects: mockedProjectsSlice.reducer,
    [projectsApiSlice.reducerPath]: projectsApiSlice.reducer,
    documents: documentSlice.reducer,
    annotations: annotationSlice.reducer,
    layers: layerSlice.reducer,
    columns: columnSlice.reducer,
    modals: modalSlice.reducer,
    application: applicationSlice.reducer,
    redlineForm: redlineFormSlice.reducer,
    branchTab: branchTabSlice.reducer,
    sld: sldSlice.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware()
      .concat(
        drawerApiSlice.middleware,
        view3DApiSlice.middleware,
        projectsApiSlice.middleware,
        sldApiSlice.middleware,
      )
      .prepend(listenerMiddleware.middleware),
  devTools: process.env.NODE_ENV !== 'production',
});

setupListeners(store.dispatch);

export default store;
