import { ANNOTATION_TYPES, TOOL_NAMES } from 'constants/apryse';

import { CreateHomerunBoxAnnotationParams } from './types';

export const createHomerunBoxTool = ({
  Annotations,
  Tools,
  documentViewer,
  annotationManager,
}: CreateHomerunBoxAnnotationParams) => {
  const HomerunBoxAnnotation = Annotations.CustomAnnotation.createFromClass(
    ANNOTATION_TYPES.HOMERUN_BOX,
    Annotations.RectangleAnnotation,
  );

  annotationManager.registerAnnotationType(
    HomerunBoxAnnotation.prototype.elementName,
    HomerunBoxAnnotation,
  );

  Annotations.setCustomDrawHandler(
    HomerunBoxAnnotation,
    function drawHandler(ctx, pageMatrix, rotation, options) {
      options.annotation.MaintainAspectRatio = true;
      options.annotation.disableRotationControl();

      // @ts-ignore
      options.annotation.elementType = ANNOTATION_TYPES.HOMERUN_BOX;
      options.originalDraw(ctx, pageMatrix, rotation);
      // @ts-ignore
      ctx.fillStyle = `rgba(${options.annotation.StrokeColor.R},${
        // @ts-ignore
        options.annotation.StrokeColor.G
        // @ts-ignore
      },${options.annotation.StrokeColor.B}, ${options.annotation.StrokeColor.A})`;
      // @ts-ignore
      ctx.font = `${this.Width / 5}px Arial`;
      ctx.textAlign = 'center';
      ctx.textBaseline = 'middle';

      // @ts-ignore
      ctx.fillText('HR', this.X + this.Width / 2, this.Y + this.Height / 2);
      ctx.restore();
    },
  );

  const HomerunBoxCreateTool = new Tools.GenericAnnotationCreateTool(
    documentViewer,
    HomerunBoxAnnotation,
  );

  const defaults = {
    StrokeColor: new Annotations.Color(153, 153, 153, 1),
    FillColor: new Annotations.Color(10, 52, 143, 1),
    Opacity: 1,
    StrokeThickness: 1,
  };

  HomerunBoxCreateTool.mouseDoubleClick = function dblClickHandler() {
    const annotation = new HomerunBoxAnnotation({
      ...defaults,
      Width: 40,
      Height: 40,
      X: this.pageCoordinates[0].x,
      Y: this.pageCoordinates[0].y,
      ToolName: TOOL_NAMES.HOMERUN_BOX,
      elementType: ANNOTATION_TYPES.HOMERUN_BOX,
    });

    annotation.disableRotationControl();

    this.getDocumentViewer().getAnnotationManager().addAnnotation(annotation);
    this.getDocumentViewer().getAnnotationManager().redrawAnnotation(annotation);
  };

  HomerunBoxCreateTool.defaults = defaults;

  return HomerunBoxCreateTool;
};
