import { WEBKIT_SCROLLBAR_STYLES } from 'constants/styles';
import { pxToRem } from 'utils';

export const styles = {
  wrapper: {
    borderRadius: pxToRem(4),
    width: '100%',
  },

  scrollWrapper: {
    width: 'inherit',
    overflowX: 'auto',
    overflowY: 'hidden',
    '&::-webkit-scrollbar': {
      height: '7.5px',
      width: '7.5px',
    },
    '&::-webkit-scrollbar-track': {
      height: '3px',
      width: '3px',
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#6E6B7B',
      borderRadius: pxToRem(4),
    },
  },
  tableWrapper: {
    flexDirection: 'column',
    borderRadius: pxToRem(4),
    borderSpacing: 0,
    flex: 'auto',
  },
  headerCell: {
    textAlign: 'left',
    fontSize: '10.5px',
    fontWeight: '600',
    padding: '7.5px',
    position: 'relative',
    '&:last-child': {
      borderRight: 0,
    },
  },
  headerCellIconWrapper: {
    position: 'absolute',
    top: '11.25%',
    right: '11.25%',
  },
  tableStyle: {
    height: '100%',
    '& *': {
      ...WEBKIT_SCROLLBAR_STYLES,
    },
  },
  bodyCell: {
    fontSize: '9px',
    padding: '7.5px',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '118.5%',
    '&:last-child': {
      borderRight: 0,
    },
    position: 'relative',
  },
  resizer: (isResizing: boolean) => ({
    background: isResizing ? 'red' : 'blue',
    display: 'inline-block',
    width: pxToRem(1),
    height: '100%',
    position: 'absolute',
    right: 0,
    top: 0,
    transform: 'translateX(50%)',
    zIndex: 1,
    touchAction: 'none',
    '&:hover': {
      background: isResizing ? 'red' : 'blue',
      width: '7.5px',
      opacity: 0.5,
    },
  }),
  tableHeaderRow: {
    backgroundColor: '#EFEEFF',
  },
  selectedRow: {
    position: 'relative',
    background: '#F3F2FA',
  },
  colorWrapper: {
    w: '15px',
    h: '15px',
    borderRadius: '2px',
    display: 'flex',
  },
  colorWrapperLeft: {
    w: '7.5px',
    h: '15px',
    borderTopLeftRadius: '2px',
    borderBottomLeftRadius: '2px',
  },
  colorWrapperRight: {
    w: '7.5px',
    h: '15px',
    borderTopRightRadius: '2px',
    borderBottomRightRadius: '2px',
  },
};
