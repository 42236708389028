import { IconButton } from '@chakra-ui/react';
import { ListIcon, TileIcon } from 'assets/icons';
import React from 'react';
import { colors } from 'theme/foundations/colors.theme';

interface ToggleViewProps {
  handleChangeDisplayMode?: (mode: string) => () => void;
  projectDisplayMode?: string;
}

export function ToggleView({
  handleChangeDisplayMode,
  projectDisplayMode,
}: ToggleViewProps): JSX.Element {
  return (
    <>
      <IconButton
        icon={
          <ListIcon
            size='15px'
            {...(projectDisplayMode === 'list' && { fill: colors.purple[100] })}
          />
        }
        variant='unstyled'
        size='15px'
        aria-label={''}
        onClick={handleChangeDisplayMode?.('list')}
      />
      <IconButton
        icon={
          <TileIcon
            size='15px'
            {...(projectDisplayMode === 'tile' && { fill: colors.purple[100] })}
          />
        }
        variant='ustyled'
        ml='14.4px'
        size='15px'
        aria-label={''}
        onClick={handleChangeDisplayMode?.('tile')}
      />
    </>
  );
}
