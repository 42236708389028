import { Box, Button, Text } from '@chakra-ui/react';
import { ProjectsLoadingErrorIcon } from 'assets/icons';
import { ERoutes } from 'components/router/types';
import React from 'react';

import { styles } from './styles';

export const Fallback = () => {
  const handleBackToHomepage = () => {
    window.location.href = `${ERoutes.PROJECTS}`;
  };

  return (
    <Box sx={styles.fallbackWrapper}>
      <ProjectsLoadingErrorIcon />
      <Text my='24px'>Opps, something went wrong!</Text>
      <Button onClick={handleBackToHomepage} variant='primary'>
        Back to Homepage
      </Button>
    </Box>
  );
};
