import { pxToRem } from 'utils';

export const styles = {
  projectCardWrapper: {
    width: '195px',
    border: '1px solid',
    borderColor: ' gray.75',
    borderRadius: pxToRem(6),
    padding: '12px 18px',
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    _hover: { border: '1px solid #7368F0' },
    height: 'max-content',
  },
  cardMenuWrapper: {
    position: 'absolute',
    right: '6px',
  },
  cardCoverWrapper: {
    width: '120px',
    height: '120px',
    borderRadius: pxToRem(6),
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow: 'hidden',
  },
  cardCoverImage: {
    objectFit: 'cover',
    width: 'inherit',
    height: 'inherit',
    cursor: 'pointer',
  },

  projectNameText: {
    fontSize: '12px',
    fontWeight: 600,
    color: 'purple.100',
    marginTop: '12px',
    marginBottom: '15px',
    cursor: 'pointer',
    display: '-webkit-box',
    maxWidth: '150px',
    WebkitLineClamp: '1',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
  },
  projectCardListWrapper: {
    width: '100%',
    td: {
      borderBottom: '1px solid #D9D5EC',
      padding: '23.25px 0px 21.75px 0px',
      maxHeight: '68px',
      '&:nth-of-type(1)': {
        paddingLeft: '18px',
      },
      '&:nth-of-type(3)': {
        paddingRight: '30px',
      },
      '&:last-child': {
        paddingRight: '18px',
      },
    },
  },
  projectName: {
    color: 'purple.100',
    fontSize: '12px',
    fontWeight: 600,
    lineHeight: '17px',
    m: '0px 30px 0px 6px',
    display: 'block',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '150px',
    width: '100%',
    cursor: 'pointer',
  },
  dateWrapper: {
    color: 'gray.400',
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '0px',
    mr: '30px',
    minW: '113px',
  },
  progress: {
    borderRadius: pxToRem(100),
    height: pxToRem(13.875),
    minW: '212px',
  },
  projectCardNameContent: {
    display: 'flex',
    alignItems: 'center',
    svg: {
      minWidth: '24px',
      minHeight: '24px',
    },
  },
  fileInfoWrapper: {
    minW: '118px',
    color: 'gray.200',
    fontSize: '10.5px',
    fontWeight: 500,
    lineHeight: '0px',
  },
  cardCoverImageList: {
    objectFit: 'cover',
    width: '24px',
    height: '24px',
    cursor: 'pointer',
  },
};
