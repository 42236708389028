// import AutoSave from 'components/auto-save';
import WebviewerProvider from 'context/webviewer/webviewer-provider';
import { IProjectPageHeaderProps } from 'types';

import SheetContent from './components/sheet-content';

const ProjectSheet = ({ path }: IProjectPageHeaderProps) => {
  return (
    <WebviewerProvider>
      <SheetContent path={path} />
      {/* <AutoSave /> */}
    </WebviewerProvider>
  );
};

export default ProjectSheet;
