import { Core } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';

type Polyline = Core.Annotations.PolylineAnnotation;

export const createMCCableTool = (instanceCore: typeof Core) => {
  const { Annotations, Tools, documentViewer, annotationManager } = instanceCore;

  const MCCableAnnotation = Annotations.CustomAnnotation.createFromClass(
    ANNOTATION_TYPES.MC_CABLE,
    Annotations.PolylineAnnotation,
  );

  annotationManager.registerAnnotationType(
    MCCableAnnotation.prototype.elementName,
    MCCableAnnotation,
  );

  Annotations.setCustomDrawHandler(
    MCCableAnnotation,
    function (ctx, pageMatrix, rotation, options) {
      (options.annotation as Polyline).Style = 'dash';
      (options.annotation as Polyline).Dashes = [10, 10];
      // @ts-ignore
      options.annotation.elementType = ANNOTATION_TYPES.MC_CABLE;

      options.originalDraw(ctx, pageMatrix, rotation);
    },
  );

  const MCCableCreateTool = new Tools.GenericAnnotationCreateTool(
    documentViewer,
    MCCableAnnotation,
  );

  const defaults = {
    StrokeColor: new Annotations.Color(3, 48, 252, 1),
    FillColor: new Annotations.Color(0, 0, 0, 1),
    Opacity: 1,
    StrokeThickness: 0.5,
  };

  MCCableCreateTool.defaults = defaults;

  const originalMouseLeftDown = instanceCore.Tools.PolylineCreateTool.prototype.mouseLeftDown;
  const originalMouseLeftUp = instanceCore.Tools.PolylineCreateTool.prototype.mouseLeftUp;
  const originalMouseMove = instanceCore.Tools.PolylineCreateTool.prototype.mouseMove;
  const originalMouseDbClick = instanceCore.Tools.PolylineCreateTool.prototype.mouseDoubleClick;
  const originalMouseRightDown = instanceCore.Tools.PolylineCreateTool.prototype.mouseRightDown;

  // Temporary solution
  MCCableCreateTool.mouseLeftDown = function () {
    // @ts-ignore
    originalMouseLeftDown.apply(this, arguments);
  };

  MCCableCreateTool.mouseLeftUp = function () {
    // @ts-ignore
    originalMouseLeftUp.apply(this, arguments);
  };

  MCCableCreateTool.mouseMove = function () {
    // @ts-ignore
    originalMouseMove.apply(this, arguments);
  };

  MCCableCreateTool.mouseDoubleClick = function () {
    // @ts-ignore
    originalMouseDbClick.apply(this, arguments);
  };

  MCCableCreateTool.mouseRightDown = function () {
    // @ts-ignore
    originalMouseRightDown.apply(this, arguments);
  };

  return MCCableCreateTool;
};
