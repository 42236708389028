import {
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from '@chakra-ui/react';
import { DOCUMENT_ID } from 'constants/apryse';
import { FormProvider, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { sldActions, useCreatePanelsConnectionMutation } from 'redux/slices';
import { SLD_TYPES } from 'types';
import { SLDFeederElement, SLDPanelElement } from 'types/redux';
import { pxToRem } from 'utils';

import { ConnectionModalInputs } from './connection-modal-inputs';
import { styles } from './styles';

interface ConnectionModalProps {
  sldFeeders: SLDFeederElement[];
  sldPanels: SLDPanelElement[];
}

export const ConnectionModal = ({ sldFeeders, sldPanels }: ConnectionModalProps) => {
  const [createConnection] = useCreatePanelsConnectionMutation();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const params = useParams();

  const dispatch = useDispatch();

  const form = useForm({
    defaultValues: { inputElement: '', outputElement: '', connectionTag: '' },
    mode: 'onBlur',
  });

  const handleCloseForm = () => {
    form.reset();
    onClose();
  };

  const watchInputElementValue = form.watch('inputElement');
  const watchOutputElementValue = form.watch('outputElement');

  return (
    <>
      <Button onClick={onOpen} mb={pxToRem(8)} sx={styles.connectionModalBtn}>
        Create connection
      </Button>
      <Modal isOpen={isOpen} onClose={handleCloseForm} isCentered size='xl'>
        <ModalOverlay />
        <FormProvider {...form}>
          <ModalContent
            sx={styles.connectionModal}
            as='form'
            onSubmit={form.handleSubmit((data) => {
              createConnection({
                projectId: params.id as string,
                drawingId: '581',
                formData: {
                  panelOneId: Number.parseInt(data.inputElement, 10),
                  panelTwoId: Number.parseInt(data.outputElement, 10),
                  feederId: Number.parseInt(data.connectionTag, 10),
                },
              }).then((response: any) => {
                if (!response.error) {
                  dispatch(
                    sldActions.addSLDConnection({
                      documentId: DOCUMENT_ID,
                      sldConnection: {
                        id: response.data.id,
                        panelOne: {
                          id: '',
                          backendId: response.data.panelOne.id,
                          panelClass: response.data.panelOne.panelClass,
                          type: SLD_TYPES.PANEL,
                          tag: response.data.panelOne.tag,
                          level: '',
                          colourCode: response.data.panelOne.colourCode,
                        },
                        panelTwo: {
                          id: '',
                          backendId: response.data.panelTwo.id,
                          panelClass: response.data.panelTwo.panelClass,
                          type: SLD_TYPES.PANEL,
                          tag: response.data.panelTwo.tag,
                          level: '',
                          colourCode: response.data.panelTwo.colourCode,
                        },
                        feeder: {
                          id: '',
                          backendId: response.data.feeder.id,
                          tag: response.data.feeder.tag,
                          colourCode: response.data.feeder.colourCode,
                          type: SLD_TYPES.FEEDER,
                        },
                      },
                    }),
                  );
                }
              });
              handleCloseForm();
            })}
          >
            <ModalHeader sx={styles.connectionModalTitle}>Create connection:</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <ConnectionModalInputs
                form={form}
                sldFeeders={sldFeeders}
                sldPanels={sldPanels}
                watchInputElementValue={watchInputElementValue}
                watchOutputElementValue={watchOutputElementValue}
              />
            </ModalBody>
            <ModalFooter>
              <Button
                colorScheme='red'
                mr={3}
                onClick={handleCloseForm}
                sx={styles.connectionModalBtn}
              >
                Cancel
              </Button>
              <Button colorScheme='green' type='submit' sx={styles.connectionModalBtn}>
                Create
              </Button>
            </ModalFooter>
          </ModalContent>
        </FormProvider>
      </Modal>
    </>
  );
};
