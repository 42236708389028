import { Box, Flex, Grid, GridItem } from '@chakra-ui/react';
import CheckboxRHF from 'components/react-hook-form/checkbox-rhf';
import ColorPickerRHF from 'components/react-hook-form/color-picker-rhf';
import ReactSelectRHF from 'components/react-hook-form/react-select-rhf';
import TextFieldRHF from 'components/react-hook-form/textfield-rhf';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { getColumnsForAnnotationInfoBlock, getSelectedAnnotations } from 'redux/selectors';
import { Annotation, ColumnData, State } from 'types/redux';

import AnnotationFieldWrapper from './annotation-field-wrapper';
import AnnotationMessage from './annotation-message';
import { useGetAnnotationDetailsValues, useGetUpdateMap } from './hooks';
import { LAYER_SELECT_STYLES, styles } from './styles';
import { useUpdateAnnotation } from './update-annotation';
import { UpdateAnnotationFormData } from './update-annotation/types';
import { getAnnotationDetailsDefaultValues } from './utils';

const ElementInfoBlock = () => {
  const allColumns = useSelector(getColumnsForAnnotationInfoBlock);
  const memoizedColumns = useMemo(() => {
    const { annotationId, _isVisible, ...rest } = allColumns;
    return rest;
  }, [allColumns]);
  // const layers = useSelector((state: State) => getLayersInDocument(state, { documentId: 1 }));
  const selectedAnnotations = useSelector<State, Annotation[]>((state) =>
    getSelectedAnnotations(state, { documentId: 1 }),
  );

  const { toggleUpdateMapItem, updateMap } = useGetUpdateMap(memoizedColumns);

  const defValues = getAnnotationDetailsDefaultValues({ columns: memoizedColumns });

  const isSpecificAnnotationSelected = Boolean(selectedAnnotations?.length);
  const doSelectedAnnotationsOfSameType = useMemo(() => {
    if (!selectedAnnotations?.length || selectedAnnotations?.length <= 1) return true;

    const typeToCompare = selectedAnnotations[0].type;

    return selectedAnnotations.every(
      (annotation: { type: any }) => annotation.type === typeToCompare,
    );
  }, [selectedAnnotations]);

  const _values = useGetAnnotationDetailsValues({
    selectedAnnotations,
    isSpecificAnnotationSelected,
    defaultValues: defValues,
  });

  const updateAnnotation = useUpdateAnnotation();

  const onSubmit = (data: Record<string, any>) => {
    updateAnnotation({
      data: data as UpdateAnnotationFormData,
      isSpecificAnnotationSelected,
      updateMap,
    });
  };

  const methods = useForm({
    defaultValues: _values,
    values: _values,
    mode: 'onBlur',
  });

  const onBlur = methods.handleSubmit(onSubmit);

  // Temporary comment
  // useEffect(() => {
  //   const layerEntities = Object.values(layers).map((layer) => ({
  //     label: layer.name,
  //     value: layer.id,
  //     id: layer.id,
  //   }));

  //   dispatch(
  //     columnActions.editColumn({
  //       id: 'layer',
  //       options: layerEntities,
  //     }),
  //   );
  // }, [layers, dispatch]);

  const renderColumn = (column: ColumnData) => {
    if (column.inputType === 'select') {
      return (
        <AnnotationFieldWrapper
          key={column.id}
          column={column}
          updatable={updateMap[column.id]}
          makeUpdatable={toggleUpdateMapItem}
        >
          <ReactSelectRHF
            name={column.id}
            disabled={column.readonly}
            options={column.options}
            styles={LAYER_SELECT_STYLES}
            onBlur={onBlur}
          ></ReactSelectRHF>
        </AnnotationFieldWrapper>
      );
    }
    if (column.inputType === 'color') {
      return (
        <AnnotationFieldWrapper
          key={column.id}
          column={column}
          updatable={updateMap[column.id]}
          makeUpdatable={toggleUpdateMapItem}
        >
          <ColorPickerRHF
            name={column.id}
            wrapperProps={{
              width: '100%',
              height: '15px',
              background: 'transparent',
              padding: '0px',
              _hover: {
                background: 'transparent',
              },
            }}
            disabled={column.readonly}
            onBlur={onBlur}
            isAnnotations={true}
          />
        </AnnotationFieldWrapper>
      );
    }
    if (column.inputType === 'checkbox') {
      return (
        <AnnotationFieldWrapper
          key={column.id}
          column={column}
          updatable={updateMap[column.id]}
          makeUpdatable={toggleUpdateMapItem}
        >
          <CheckboxRHF name={column.id} isDisabled={column.readonly} onBlur={onBlur} />
        </AnnotationFieldWrapper>
      );
    }

    return (
      <AnnotationFieldWrapper
        key={column.id}
        column={column}
        updatable={updateMap[column.id]}
        makeUpdatable={toggleUpdateMapItem}
      >
        <TextFieldRHF
          type={column.inputType}
          name={column.id}
          wrapperProps={{
            width: '100%',
            height: '23.25px',
          }}
          inputStyles={{
            padding: '3.75px 6px 3px 6px',
            fontSize: '7.5px',
            lineHeight: '16.5px',
            background: 'white',
            borderColor: 'gray.350',
            '&::placeholder': {
              fontWeight: 400,
              fontSize: '7.5px',
            },
          }}
          placeholder='Type here'
          disabled={column.readonly}
          onBlur={onBlur}
        />
      </AnnotationFieldWrapper>
    );
  };

  if (!isSpecificAnnotationSelected) {
    return (
      <AnnotationMessage message='Please, select any annotation(s) in order to configure the details' />
    );
  }

  if (!doSelectedAnnotationsOfSameType) {
    return <AnnotationMessage message='Please, select the annotations with the same type' />;
  }

  return (
    <FormProvider {...methods}>
      <Box as='form' onSubmit={methods.handleSubmit(onSubmit)} h='full' w='full'>
        <Flex sx={styles.elementInfoWrapper}>
          <Grid sx={styles.scrollWrapper} templateColumns='1fr 1fr' columnGap='12px'>
            {Object.values(allColumns).map((column) => {
              return (
                <GridItem {...(column.id === 'elementType' && { colSpan: 2 })}>
                  {renderColumn(column)}
                </GridItem>
              );
            })}
          </Grid>
        </Flex>
      </Box>
    </FormProvider>
  );
};

export default ElementInfoBlock;
