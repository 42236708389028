import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { DropzoneFile } from 'types';

export const useCreateImageUrl = (url: string) => {
  const [imageUrl, setImgUrl] = useState<string | null>(null);
  const token = localStorage.getItem('ROCP_token');
  const [isImageLoading, setIsImageLoading] = useState(false);
  const [file, setFile] = useState<DropzoneFile | null>(null);

  const params = useParams();
  const sheetId = params.sheetId;

  useEffect(() => {
    let objectUrl: null | string = null;
    if (token) {
      setIsImageLoading(true);
      fetch(url, {
        headers: {
          Authorization: `Bearer ${token.replaceAll('"', '')}`,
        },
      })
        .then((res) => {
          if (!res.ok) {
            throw new Error('No preview image for project');
          }

          return res.blob();
        })
        .then((data) => {
          const file = new File([data], `filename-${sheetId}`, {
            type: 'application/pdf',
          });

          objectUrl = URL.createObjectURL(data);
          setImgUrl(objectUrl);
          setFile(
            Object.assign(file, {
              preview: objectUrl,
              path: `filename-${sheetId}.pdf`,
            }),
          );
        })
        .catch((err) => console.error(err))
        .finally(() => setIsImageLoading(false));
    }
    return () => {
      if (objectUrl) URL.revokeObjectURL(objectUrl);
    };
  }, [token, url]);
  return { imageUrl, isImageLoading, file };
};
