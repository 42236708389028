import { Core } from '@pdftron/webviewer';
import { ANNOTATION_TYPES } from 'constants/apryse';
import { PANEL_TYPE_DATA, PANELS } from 'pages/sheet/components/apryse-block/constants';

import { PanelType } from './types';

const getElementType = (panelType: PANELS) => {
  return {
    [PANELS.CRITICAL]: ANNOTATION_TYPES.CRITICAL_PANEL,
    [PANELS.CRITICAL_HORIZONTAL]: ANNOTATION_TYPES.CRITICAL_PANEL_HORIZONTAL,
    [PANELS.NORMAL]: ANNOTATION_TYPES.NORMAL_PANEL,
    [PANELS.NORMAL_HORIZONTAL]: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
  }[panelType];
};

export const createPanelTool = (
  instanceCore: typeof Core,
  panelType: PanelType,
  panelText: string,
) => {
  const { Annotations, Tools, documentViewer, annotationManager } = instanceCore;
  const annotationTypes = {
    [PANELS.CRITICAL]: ANNOTATION_TYPES.CRITICAL_PANEL,
    [PANELS.CRITICAL_HORIZONTAL]: ANNOTATION_TYPES.CRITICAL_PANEL_HORIZONTAL,
    [PANELS.NORMAL]: ANNOTATION_TYPES.NORMAL_PANEL,
    [PANELS.NORMAL_HORIZONTAL]: ANNOTATION_TYPES.NORMAL_PANEL_HORIZONTAL,
  };
  const PanelAnnotation = Annotations.CustomAnnotation.createFromClass(
    annotationTypes[panelType as PANELS],
    Annotations.RectangleAnnotation,
  );
  const isHorizontal = panelType.includes('HORIZONTAL');

  const Rotation = isHorizontal ? 0 : 270;
  annotationManager.registerAnnotationType(PanelAnnotation.prototype.elementName, PanelAnnotation);

  Annotations.setCustomDrawHandler(PanelAnnotation, function (ctx, pageMatrix, rotation, options) {
    options.annotation.Rotation = options.annotation.Rotation || Rotation;
    options.annotation.disableRotationControl();
    // @ts-ignore
    options.annotation.elementType = getElementType(panelType);

    options.originalDraw(ctx, pageMatrix, rotation);

    // @ts-ignore
    const fontSize = this.Width * 0.1;

    ctx.save();
    ctx.fillStyle = '#FFFFFF';
    ctx.textAlign = 'center';
    ctx.textBaseline = 'middle';
    ctx.font = `100 ${fontSize || 0}px sans-serif`;
    // @ts-ignore
    const X = this.X + this.Width / 2;
    // @ts-ignore
    const Y = this.Y + this.Height / 2;
    ctx.fillText(panelText, X, Y);
    ctx.restore();
  });

  const PanelCreateTool = new Tools.GenericAnnotationCreateTool(documentViewer, PanelAnnotation);

  const defaults = {
    StrokeColor: panelType.includes('CRITICAL')
      ? new Annotations.Color(255, 0, 0, 1)
      : new Annotations.Color(0, 0, 255, 1),
    FillColor: panelType.includes('CRITICAL')
      ? new Annotations.Color(255, 0, 0, 0.5)
      : new Annotations.Color(0, 0, 255, 0.5),
    Opacity: 1,
    StrokeThickness: 1,
    Rotation,
  };

  PanelCreateTool.mouseDoubleClick = function dblClickHandler() {
    const annotation = new PanelAnnotation({
      ...defaults,
      Width: isHorizontal ? 100 : 40,
      Height: isHorizontal ? 40 : 100,
      X: this.pageCoordinates[0].x,
      Y: this.pageCoordinates[0].y,
      ToolName: PANEL_TYPE_DATA[panelType].toolName,
      elementType: getElementType(panelType as PANELS),
    });

    annotation.disableRotationControl();

    this.getDocumentViewer().getAnnotationManager().addAnnotation(annotation);
    this.getDocumentViewer().getAnnotationManager().redrawAnnotation(annotation);
  };

  PanelCreateTool.defaults = defaults;

  return PanelCreateTool;
};
