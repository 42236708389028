import { Box, IconButton } from '@chakra-ui/react';
import { AddCircleLineIcon, CloseIcon } from 'assets/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getActiveTabIndex, isTabOpen } from 'redux/selectors';
import { branchTabActions } from 'redux/slices';

import { tabPanelArray } from './constants';
import { styles } from './styles';

export const TabPanelContent = () => {
  const activeTabIndex = useSelector(getActiveTabIndex);
  const isOpen = useSelector(isTabOpen);
  const dispatch = useDispatch();

  const handleClose = () => {
    dispatch(branchTabActions.toggleTab({ isOpen: false }));
  };

  const handleClickAdd = () => {
    dispatch(branchTabActions.toggleTabModal({ isTabModalOpen: true }));
  };

  if (isOpen && activeTabIndex !== 8) {
    return (
      <Box sx={styles.tabPanel}>
        <Box sx={styles.tabPanelHeader}>
          {tabPanelArray[activeTabIndex - 1].title}
          <Box display='flex'>
            {tabPanelArray[activeTabIndex - 1].withModal && (
              <IconButton
                aria-label='Add items'
                icon={<AddCircleLineIcon size='15px' />}
                sx={styles.addButton}
                onClick={handleClickAdd}
              />
            )}
            <IconButton
              aria-label='Close menu'
              icon={<CloseIcon size='15px' />}
              sx={styles.closeButton}
              onClick={handleClose}
            />
          </Box>
        </Box>
        {tabPanelArray[activeTabIndex - 1].content}
      </Box>
    );
  }
  return null;
};
