import { Box, Text, VStack } from '@chakra-ui/react';
import AddFileWindow from 'components/add-file-window';
import { COMMON_DOCUMENT_PROPS } from 'constants/index';
import { styles } from 'pages/add-project/components/add-file/add-file.styles';
import { FileCard } from 'pages/add-project/components/file-card';
import React, { ReactElement, useEffect } from 'react';
import { DropzoneInputProps, DropzoneRootProps } from 'react-dropzone';
import { useDispatch, useSelector } from 'react-redux';
import { getUploadDocuments } from 'redux/selectors';
import { documentActions } from 'redux/slices';
import { DropzoneFile } from 'types';
import { Document } from 'types/redux';

interface IAddFileProps {
  selectedFiles: DropzoneFile[];
  getRootProps: () => DropzoneRootProps;
  getInputProps: () => DropzoneInputProps;
  handleDeleteFile: (fileToDelete: DropzoneFile) => void;
}

export function AddFile({
  selectedFiles,
  getRootProps,
  getInputProps,
  handleDeleteFile,
}: IAddFileProps): ReactElement {
  const loadedDocuments = useSelector(getUploadDocuments);

  const dispatch = useDispatch();

  useEffect(() => {
    selectedFiles.forEach((file, idx) => {
      dispatch(
        documentActions.uploadDocument({
          ...COMMON_DOCUMENT_PROPS,
          id: new Date().getTime() * (idx + 1),
          name: file.name,
          preview: file.preview,
          lastModified: file.lastModified,
          size: file.size,
          type: file.type,
          scale: null,
        }),
      );
    });
  }, [selectedFiles, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(documentActions.deleteUploadedDocuments());
    };
  }, [dispatch]);

  return (
    <>
      <Box sx={styles.uploadWrapper}>
        <Text sx={styles.uploadText}>Upload files to the project</Text>
        <Box {...(selectedFiles.length > 0 ? null : { ...getRootProps() })}>
          <input {...getInputProps()} name='file' />
          <AddFileWindow isDisabled={selectedFiles.length > 0} />
        </Box>
      </Box>
      <VStack spacing='9px'>
        {Object.values(loadedDocuments).map((file: Document, idx: number) => (
          <FileCard
            id={file.id}
            key={idx + 1}
            file={file}
            handleDeleteFileFromDropzone={handleDeleteFile}
          />
        ))}
      </VStack>
    </>
  );
}
