import { Flex, Image, Progress, Td, Text, Tr } from '@chakra-ui/react';
import { FolderOutlineIcon } from 'assets/icons';
import { ERoutes } from 'components/router/types';
import dayjs from 'dayjs';
import { useCreateImageUrl } from 'hooks/useCreateImageUrl';
import { styles } from 'pages/projects/components/project-card/project-card.styles';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { DrawerProject } from 'types';
import { cropText, formatBytes } from 'utils';

import { ProjectCardMenu } from '../menu/project-card-menu';

interface IProjectCardProps {
  projectData: DrawerProject;
}

export const ProjectCardListItem = React.forwardRef(
  ({ projectData }: IProjectCardProps, ref: any) => {
    const navigate = useNavigate();
    const handleLinkClick = () => {
      navigate(`${ERoutes.PROJECTS}/${projectData?.id}${ERoutes.REPORTS}`);
    };
    const { imageUrl } = useCreateImageUrl(
      `${process.env.REACT_APP_PROJECTS_API}projects/${projectData.id}/preview`,
    );

    const creationDate = dayjs(projectData?.createdAt).format('DD.MM.YYYY, HH:MM');
    const filesCount = projectData?.filesMeta?.totalFilesCount || 0;

    return (
      <Tr ref={ref} sx={styles.projectCardListWrapper}>
        <Td sx={styles.projectCardNameContent}>
          {imageUrl?.length ? (
            <Image
              src={imageUrl}
              alt='cover'
              sx={styles.cardCoverImageList}
              onClick={handleLinkClick}
            />
          ) : (
            <FolderOutlineIcon size='24px' />
          )}
          <Text sx={styles.projectName} onClick={handleLinkClick}>
            {projectData?.name}
          </Text>
        </Td>
        <Td>
          <Text onClick={handleLinkClick} sx={styles.dateWrapper}>
            {cropText(creationDate || '', 19)}
          </Text>
        </Td>
        <Td>
          <Progress value={100} sx={styles.progress} />
        </Td>
        <Td>
          <Flex sx={styles.fileInfoWrapper}>
            <Text onClick={handleLinkClick} mr='8px'>{`${filesCount} files`}</Text>
            <Text onClick={handleLinkClick}>
              {formatBytes(projectData?.filesMeta?.totalFilesSize)}
            </Text>
          </Flex>
        </Td>
        <Td>
          <ProjectCardMenu />
        </Td>
      </Tr>
    );
  },
);
