import { Box, Flex, Text } from '@chakra-ui/react';
import Card from 'components/card';
import { HeaderBreadcrumbs, TBreadcrumb } from 'components/header-breadcrums';
import { SearchInput } from 'components/page-header/components/search-input/search-input';
import { SortingMenu } from 'components/page-header/components/sorting-menu/sorting-menu';
import { ToggleView } from 'components/page-header/components/toggle-view/toggle-view';
import { HEADER_SORT_DEFAULT_VALUE, HEADER_SORT_VARIANTS } from 'components/page-header/constants';
import { styles } from 'components/page-header/styles';
import React from 'react';

interface IPageHeader {
  title: string;
  actionButtons: JSX.Element;
  withSearch?: boolean;
  withSortingMenu?: boolean;
  withoutToggle?: boolean;
  searchValue?: string;
  onSearchChange?: (newValue: string) => void;
  breadcrumbs?: TBreadcrumb[];
  headerHeight?: string;
  handleChangeDisplayMode?: (mode: string) => () => void;
  projectDisplayMode?: string;
}

export function PageHeader({
  title,
  actionButtons,
  withSearch,
  onSearchChange,
  withSortingMenu,
  withoutToggle,
  searchValue,
  breadcrumbs,
  headerHeight = '45px',
  handleChangeDisplayMode,
  projectDisplayMode,
}: IPageHeader): JSX.Element {
  return (
    <Card wrapperProps={{ ...styles.headerCard, height: headerHeight, minHeight: headerHeight }}>
      <Box sx={styles.headerContent}>
        <Flex align='center'>
          <Text sx={styles.heading}>{title}</Text>
          {breadcrumbs?.length && <HeaderBreadcrumbs breadcrumbs={breadcrumbs} />}
        </Flex>
        <Box sx={styles.headerRightSection}>
          {withSearch && <SearchInput value={searchValue} onChange={onSearchChange} />}
          {withSortingMenu && (
            <SortingMenu
              sortVariants={HEADER_SORT_VARIANTS}
              defaultValue={HEADER_SORT_DEFAULT_VALUE}
            />
          )}
          {!withoutToggle && (
            <ToggleView
              handleChangeDisplayMode={handleChangeDisplayMode}
              projectDisplayMode={projectDisplayMode}
            />
          )}
          {actionButtons}
        </Box>
      </Box>
    </Card>
  );
}
