import { HStack, Select, Text } from '@chakra-ui/react';
import { Controller, UseFormReturn } from 'react-hook-form';
import { SLDFeederElement, SLDPanelElement } from 'types/redux';
import { pxToRem } from 'utils';

import { styles } from './styles';

interface ConnectionModalInputsProps {
  sldPanels: SLDPanelElement[];
  sldFeeders: SLDFeederElement[];
  watchInputElementValue: string;
  watchOutputElementValue: string;
  form: UseFormReturn<
    { inputElement: string; outputElement: string; connectionTag: string },
    any,
    undefined
  >;
}

enum SELECT_VARIANTS {
  INPUT_ELEMENT = 'inputElement',
  OUTPUT_ELEMENT = 'outputElement',
  CONNECTION_TAG = 'connectionTag',
}

export const ConnectionModalInputs = ({
  sldFeeders,
  sldPanels,
  form,
  watchInputElementValue,
  watchOutputElementValue,
}: ConnectionModalInputsProps) => {
  return (
    <HStack spacing={pxToRem(11)} w='100%' mb={pxToRem(8)} alignItems='center'>
      <Controller
        name={SELECT_VARIANTS.INPUT_ELEMENT}
        control={form.control}
        render={({ field }) => (
          <Select
            placeholder='Choose element...'
            sx={styles.select}
            background='#EFEEFF'
            {...field}
            required
          >
            {sldPanels?.map((panelOption: SLDPanelElement, idx: number) => {
              if (panelOption.backendId.toString() !== watchOutputElementValue) {
                return (
                  <option key={idx} value={panelOption.backendId}>
                    {panelOption.tag}
                  </option>
                );
              }
            })}
          </Select>
        )}
      />
      <Text color='purple.100' fontWeight={500} lineHeight='16px' fontSize='10.5px'>
        -
      </Text>
      <Controller
        name={SELECT_VARIANTS.CONNECTION_TAG}
        control={form.control}
        render={({ field }) => (
          <Select placeholder='Tag' sx={styles.select} w={pxToRem(225)} {...field} required>
            {sldFeeders?.map((feederOption: SLDFeederElement, idx: number) => (
              <option key={idx} value={feederOption.backendId}>
                {feederOption.tag}
              </option>
            ))}
          </Select>
        )}
      />
      <Text color='purple.100' fontWeight={500} lineHeight='16px' fontSize='10.5px'>
        -
      </Text>
      <Controller
        name={SELECT_VARIANTS.OUTPUT_ELEMENT}
        control={form.control}
        render={({ field }) => (
          <Select placeholder='Choose element...' sx={styles.select} {...field} required>
            {sldPanels?.map((panelOption: SLDPanelElement, idx: number) => {
              if (panelOption.backendId.toString() !== watchInputElementValue) {
                return (
                  <option key={idx} value={panelOption.backendId}>
                    {panelOption.tag}
                  </option>
                );
              }
            })}
          </Select>
        )}
      />
    </HStack>
  );
};
