import {
  BUTTON_ICONS,
  BUTTON_NAMES,
  TOOL_NAMES,
  TOOLTIPS,
  UPPER_TOOLBAR_MESURE_INDEX,
} from 'constants/apryse';

import { POLYGON_TOOL_GROUP_BUTTON, TOOL_BUTTON, TOOLBAR_GROUP } from '../constants';
import { AddToolToPanelParams } from './types';

export const addSLDSelectorToolToPanel = ({ UI, tool }: AddToolToPanelParams) => {
  UI.registerTool({
    toolName: TOOL_NAMES.SLD_SELECTOR,
    toolObject: tool,
    tooltip: TOOLTIPS.SLD_SELECTOR,
    buttonName: BUTTON_NAMES.SLD_SELECTOR,
    buttonImage: BUTTON_ICONS.SLD_SELECTOR,
  });

  UI.setHeaderItems((header) => {
    header.getHeader(TOOLBAR_GROUP.SHAPES).get(POLYGON_TOOL_GROUP_BUTTON).insertBefore({
      type: TOOL_BUTTON,
      toolName: TOOL_NAMES.SLD_SELECTOR,
    });
  });

  UI.setHeaderItems((header) => {
    const customSLDSelectorButton = {
      type: TOOL_BUTTON,
      toolName: TOOL_NAMES.SLD_SELECTOR,
    };
    const items = header.getItems();
    items.splice(UPPER_TOOLBAR_MESURE_INDEX, 0, customSLDSelectorButton);
  });
};
