import React from 'react';
import { useDropzone } from 'react-dropzone';
import { DropzoneFile } from 'types';

export const useFileUpload = () => {
  const [selectedFiles, setSelectedFiles] = React.useState<DropzoneFile[]>([]);

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'application/pdf': ['.pdf'],
    },
    maxFiles: 1,
    multiple: false,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    onDrop: (acceptedFiles: any[]) => {
      setSelectedFiles(
        acceptedFiles.map((file) =>
          Object.assign(file, {
            preview: URL.createObjectURL(file),
          }),
        ),
      );
    },
  });

  /* Memory cleaning temporary disabled due to developing purposes */
  // React.useEffect(
  //   () => () => {
  //     selectedFiles.forEach((file: { preview: string }) => URL.revokeObjectURL(file.preview));
  //   },
  //   [selectedFiles],
  // );

  const handleResetFiles = () => {
    setSelectedFiles([]);
  };

  const handleDeleteFile = (fileToDelete: DropzoneFile) => {
    setSelectedFiles((prev: DropzoneFile[]) =>
      prev.filter((file) => file.preview !== fileToDelete.preview),
    );
  };

  return {
    selectedFiles,
    setSelectedFiles,
    onDeleteFile: handleDeleteFile,
    onResetFiles: handleResetFiles,
    getRootProps,
    getInputProps,
  };
};
