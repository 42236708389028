import { State } from 'types/redux';

export const isSLDModalOpen = (state: State) => state.sld.isModalOpen;

export const getSLDConnections = (state: State, { documentId }: { documentId: number }) =>
  state.sld.sldList[documentId]?.connectionList;

export const getSLDFeeders = (state: State, { documentId }: { documentId: number }) =>
  state.sld.sldList[documentId]?.sldFeeders;

export const getSLDPanels = (state: State, { documentId }: { documentId: number }) =>
  state.sld.sldList[documentId]?.sldPanels;
