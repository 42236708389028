import {
  AlertDialogBody,
  AlertDialogFooter,
  Button,
  Flex,
  Grid,
  GridItem,
  Icon,
  IconButton,
  Text,
} from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactSelectRHF from 'components/react-hook-form/react-select-rhf';
import SwitchRHF from 'components/react-hook-form/switch-rhf';
import TextFieldRHF from 'components/react-hook-form/textfield-rhf';
import { FormEventHandler, forwardRef, useEffect, useMemo, useState } from 'react';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { AiFillDelete } from 'react-icons/ai';
import { FaSave } from 'react-icons/fa';
import { IoAdd } from 'react-icons/io5';
import { useDispatch, useSelector } from 'react-redux';
import { getColumns } from 'redux/selectors';
import { columnActions } from 'redux/slices/column.slice';
import { ColumnData, ModalState, Option } from 'types/redux';
import { convertRGBtoHex, isInRGBAFormat } from 'utils/rgb-hex';
import { manageCustomColumn } from 'validation/schemas';

import { DEFAULT_COLUMN_VALUES, INPUT_TYPES_OPTIONS } from './constants';
import { styles } from './styles';
import { generateIdFromLabel, renderDefaultValueInput } from './utils';

interface FormContentProps {
  onClose: () => void;
  modalData?: ModalState['data'];
}

const FormContent = forwardRef<HTMLButtonElement, FormContentProps>(
  ({ onClose, modalData }, cancelRef) => {
    const dispatch = useDispatch();

    const [selectOptions, setSelectOptions] = useState<Option[]>([]);

    const isEditMode = Boolean(modalData?.id);
    const allColumns = useSelector(getColumns);

    const memoColumnLabels = useMemo(() => {
      const columnLabels = [];

      for (const column in allColumns) {
        if (Object.hasOwnProperty.call(allColumns, column)) {
          const iteratedColumnLabel = allColumns[column].label;
          columnLabels.push(iteratedColumnLabel);
        }
      }
      return columnLabels;
    }, [allColumns]);

    const DEFAULT_VALUES = useMemo(() => {
      const _values = {
        ...DEFAULT_COLUMN_VALUES,
        ...modalData,
      };

      if (isInRGBAFormat(_values.defaultValue)) {
        _values.defaultValue = convertRGBtoHex(_values.defaultValue);
      }

      return _values;
    }, [modalData]);

    useEffect(() => {
      if (modalData?.options) setSelectOptions(modalData.options as Option[]);
    }, [modalData?.options]);

    const methods = useForm<ColumnData>({
      defaultValues: DEFAULT_VALUES,
      resolver: yupResolver(manageCustomColumn),
      mode: 'onBlur',
    });

    const inputTypeValue = methods.watch('inputType');

    const isSelectTypeChosen = inputTypeValue === INPUT_TYPES_OPTIONS.select.value;

    const onSubmit: FormEventHandler<HTMLFormElement> = (event) => {
      event.preventDefault();
      event.stopPropagation();

      const createCallback: SubmitHandler<ColumnData> = (data) => {
        const columnId = generateIdFromLabel(data);
        if (!memoColumnLabels.includes(data.label)) {
          dispatch(
            columnActions.addNewColumn({
              ...data,
              id: columnId,
              ...(isSelectTypeChosen && { options: selectOptions }),
            }),
          );

          onClose();
        } else {
          methods.setError('label', { message: 'The column label must be unique' });
        }
      };

      const editCallback: SubmitHandler<ColumnData> = (data) => {
        dispatch(
          columnActions.editColumn({
            ...data,
            ...(isSelectTypeChosen && { options: selectOptions }),
          }),
        );

        onClose();
      };

      methods.handleSubmit(isEditMode ? editCallback : createCallback)();
    };

    const addOption = () => {
      // @ts-ignore
      const newOptionValue = methods.getValues('newOption');

      if (newOptionValue) {
        setSelectOptions((prev) => [
          ...prev,
          { label: newOptionValue, value: newOptionValue.toLowerCase(), id: newOptionValue },
        ]);

        // @ts-ignore
        methods.setValue('newOption', '');
      }
    };

    const removeOption = (id: string | number) => {
      const selectedDefaultValue = methods.getValues('defaultValue');
      const isRemovableOptionSelected =
        selectOptions.find((option) => option.id === id)?.value === selectedDefaultValue;

      if (isRemovableOptionSelected) {
        // @ts-ignore
        methods.setValue('defaultValue', undefined);
      }

      setSelectOptions((prev) => prev.filter((option) => option.id !== id));
    };

    return (
      <>
        <FormProvider {...methods}>
          <form onSubmit={onSubmit}>
            <AlertDialogBody>
              <Grid templateColumns='repeat(2, 1fr)' columnGap='3px' rowGap='2.25px'>
                <GridItem colSpan={2}>
                  <Text sx={{ ...styles.customColumnFieldLabel, mt: 0 }}>Label</Text>
                  <TextFieldRHF
                    name='label'
                    placeholder='Column label'
                    wrapperProps={{ minHeight: '30px' }}
                    height='30px'
                  />
                </GridItem>
                <GridItem colSpan={2}>
                  <Text sx={styles.customColumnFieldLabel}>Input Type</Text>
                  <ReactSelectRHF
                    name='inputType'
                    placeholder='Select input type'
                    styles={{ control: (base) => ({ ...base, height: '30px' }) }}
                    options={Object.values(INPUT_TYPES_OPTIONS)}
                  />
                </GridItem>
                {isSelectTypeChosen && (
                  <GridItem colSpan={2} mt={2}>
                    <Text sx={styles.customColumnFieldLabel}>Create options</Text>
                    <Flex>
                      <TextFieldRHF
                        name='newOption'
                        placeholder='Create new option'
                        wrapperProps={{ height: '30px' }}
                      />
                      <Button
                        bg='purple.100'
                        color='white'
                        ml='2'
                        fontSize='sm'
                        onClick={addOption}
                      >
                        Add
                      </Button>
                    </Flex>
                  </GridItem>
                )}
                {isSelectTypeChosen && Boolean(selectOptions.length) && (
                  <GridItem colSpan={2} mt={2}>
                    {selectOptions.map((option, index) => (
                      <Flex
                        _even={{ bg: 'purple.25' }}
                        key={index}
                        px={2}
                        py={1}
                        justifyContent='space-between'
                        alignItems='center'
                      >
                        {option.label}
                        <Icon
                          as={AiFillDelete}
                          cursor='pointer'
                          onClick={() => removeOption(option.id)}
                          color='red.700'
                          _hover={{ color: 'red.500' }}
                        />
                      </Flex>
                    ))}
                  </GridItem>
                )}
                <GridItem colSpan={2} mt={2}>
                  <Text sx={styles.customColumnFieldLabel}>Default Value</Text>
                  {!!inputTypeValue &&
                    renderDefaultValueInput({
                      inputType: inputTypeValue,
                      selectOptions,
                    })}
                </GridItem>

                <GridItem colSpan={2} display='flex' justifyContent='space-between'>
                  <Text sx={styles.customColumnFieldLabel}>Readonly</Text>
                  <SwitchRHF name='readonly' />
                </GridItem>
              </Grid>
            </AlertDialogBody>

            <AlertDialogFooter mt={2.5}>
              <Button ref={cancelRef} onClick={onClose} fontSize='12px'>
                Cancel
              </Button>
              <IconButton
                colorScheme='purple'
                type='submit'
                ml='6px'
                px='12px'
                icon={
                  <>
                    {isEditMode ? <FaSave size='14px' /> : <IoAdd size='14px' />}
                    <Text ml='6px' fontSize='12px'>
                      {isEditMode ? 'Save' : 'Create'}
                    </Text>
                  </>
                }
                aria-label='Add custom'
              />
            </AlertDialogFooter>
          </form>
        </FormProvider>
      </>
    );
  },
);

export default FormContent;
