import { ANNOTATION_TYPES, TOOL_NAMES } from 'constants/apryse';

import { CreateHomerunBoxAnnotationParams } from './types';

export const createCircuitTool = ({
  Annotations,
  Tools,
  documentViewer,
  annotationManager,
}: CreateHomerunBoxAnnotationParams) => {
  const CircuitAnnotation = Annotations.CustomAnnotation.createFromClass(
    ANNOTATION_TYPES.CIRCUIT,
    Annotations.RectangleAnnotation,
  );

  Annotations.setCustomDrawHandler(
    CircuitAnnotation,
    function drawHandler(ctx, pageMatrix, rotation, options) {
      // @ts-ignore
      options.annotation.elementType = ANNOTATION_TYPES.CIRCUIT;
      options.annotation.disableRotationControl();
      options.originalDraw(ctx, pageMatrix, rotation);
    },
  );

  annotationManager.registerAnnotationType(
    CircuitAnnotation.prototype.elementName,
    CircuitAnnotation,
  );

  const CircuitCreateTool = new Tools.GenericAnnotationCreateTool(
    documentViewer,
    CircuitAnnotation,
  );

  const defaults = {
    StrokeColor: new Annotations.Color(255, 20, 0, 1),
    FillColor: new Annotations.Color(255, 0, 0, 0.1),
    Opacity: 1,
    StrokeThickness: 1,
  };

  CircuitCreateTool.mouseDoubleClick = function dblClickHandler() {
    const annotation = new CircuitAnnotation({
      ...defaults,
      Width: 7,
      Height: 7,
      X: this.pageCoordinates[0].x,
      Y: this.pageCoordinates[0].y,
      ToolName: TOOL_NAMES.HOMERUN_BOX,
      elementType: ANNOTATION_TYPES.CIRCUIT,
    });

    annotation.disableRotationControl();

    this.getDocumentViewer().getAnnotationManager().addAnnotation(annotation);
    this.getDocumentViewer().getAnnotationManager().redrawAnnotation(annotation);
  };

  CircuitCreateTool.defaults = defaults;

  return CircuitCreateTool;
};
