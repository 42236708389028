// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import { COMMON_DOCUMENT_PROPS } from 'constants/index.ts';
import { useCreateImageUrl } from 'hooks/useCreateImageUrl';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { documentActions, ProjectsApiUrl } from 'redux/slices';

export const useGetDrawing = () => {
  const { id = '', sheetId = '' } = useParams();
  const drawingRequestUrl = ProjectsApiUrl.DRAWING({
    projectId: id,
    sheetId,
  }).substring(1);
  const dispatch = useDispatch();

  const requestUrl = `${process.env.REACT_APP_PROJECTS_API}${drawingRequestUrl}`;
  const { file, isImageLoading } = useCreateImageUrl(requestUrl);
  useEffect(() => {
    if (file) {
      dispatch(
        documentActions.openDocument({
          ...COMMON_DOCUMENT_PROPS,
          id: new Date().getTime(),
          name: file.name,
          preview: file.preview,
          lastModified: file.lastModified,
          size: file.size,
          type: file.type,
          scale: null,
        }),
      );
    }
  }, [dispatch, file]);

  return { file, isLoading: isImageLoading };
};
