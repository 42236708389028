import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import dayjs from 'dayjs';

import { ColumnData, ColumnsState, EditColumnData } from '../../types/redux';

const allColumns = {
  annotationId: {
    label: 'Select',
    defaultValue: '',
    id: 'annotationId',
    sortType: 'basic',
    readonly: true,
    hiddenAIB: true, // hiddenAIB - hidden in Annotation Info Block
  },
  _isVisible: {
    defaultValue: '',
    sortType: 'basic',
    id: '_isVisible',
    label: 'Visibility',
    readonly: true,
    hiddenAIB: true,
  },

  elementType: {
    id: 'elementType',
    label: 'Element Type',
    defaultValue: '',
    readonly: true,
    inputType: 'text',
  },
  fillColor: {
    id: 'fillColor',
    label: 'Fill Color',
    defaultValue: 'rgba(0,0,0,0)',
    readonly: false,
    inputType: 'color',
  },
  strokeColor: {
    id: 'strokeColor',
    label: 'Stroke Color',
    defaultValue: 'rgba(0,0,0,0)',
    readonly: false,
    inputType: 'color',
  },
  conduit_id: {
    id: 'conduit_id',
    label: 'Conduit ID',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  circuits: {
    id: 'circuits',
    label: 'Circuits',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  panels: {
    id: 'panels',
    label: 'Panels',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  origin: {
    id: 'origin',
    label: 'Origin',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  panelName: {
    id: 'panelName',
    label: 'Panel Name',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  destination: {
    id: 'destination',
    label: 'Destination',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  voltageType: {
    id: 'voltageType',
    label: 'Voltage Type',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  level: {
    id: 'level',
    label: 'Level',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  conduitSize: {
    id: 'conduitSize',
    label: 'Conduit Size',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  wireSize: {
    id: 'wireSize',
    label: 'Wire Size',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  wireCount: {
    id: 'wireCount',
    label: 'Wire Count',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  groundSize: {
    id: 'groundSize',
    label: 'Ground Size',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  wireType: {
    id: 'wireType',
    label: 'Wire Type',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  amperage: {
    id: 'amperage',
    label: 'Amperage',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  voltageDrop: {
    id: 'voltageDrop',
    label: 'Voltage Drop',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  wireFill: {
    id: 'wireFill',
    label: 'Wire Fill',
    defaultValue: '',
    readonly: false,
    inputType: 'text',
  },
  // Temporary comment
  // layer: {
  //   id: 'layer',
  //   label: 'Layer',
  //   defaultValue: '',
  //   readonly: false,
  //   inputType: 'select',

  //   options: [],
  // },
  group: {
    id: 'group',
    label: 'Group',
    readonly: false,
    inputType: 'checkbox',
  },
  author: {
    id: 'author',
    label: 'Author',
    defaultValue: '',
    readonly: true,
    inputType: 'text',
  },
  measurments: {
    id: 'measurments',
    label: 'Measurments',
    defaultValue: '',
    readonly: true,
    inputType: 'text',
  },
  updatedAt: {
    id: 'updatedAt',
    label: 'Updated at',
    defaultValue: dayjs().format('YYYY-MM-DD'),
    readonly: true,
    inputType: 'date',
  },
};
const INITIAL_STATE: ColumnsState = {
  allColumns,
  nonExportableColumnsIds: [],
  // @ts-ignore
  columnsOrder: Object.keys(allColumns).map((key) => allColumns[key].id),
};

export const columnSlice = createSlice({
  name: 'columns',
  initialState: INITIAL_STATE,
  reducers: {
    addNewColumn(state: ColumnsState, action: PayloadAction<ColumnData>) {
      state.allColumns[action.payload.id] = action.payload;
      state.columnsOrder = [...state.columnsOrder, action.payload.id];
    },
    editColumn(state: ColumnsState, action: PayloadAction<EditColumnData>) {
      state.allColumns[action.payload.id] = {
        ...state.allColumns[action.payload.id],
        ...action.payload,
      };
    },
    setColumnsDefaultValues(
      state: ColumnsState,
      action: PayloadAction<{ id: ColumnData['id']; value: ColumnData['defaultValue'] }[]>,
    ) {
      action.payload.forEach((column) => {
        state.allColumns[column.id].defaultValue = column.value;
      });
    },
    toggleColumnExportPermission(
      state: ColumnsState,
      action: PayloadAction<{
        columnId: string;
      }>,
    ) {
      state.nonExportableColumnsIds.push(action.payload.columnId);
      // const columnToToggle = state.allColumns[action.payload.columnId];
      // if (columnToToggle) {
      //   if (typeof action.payload.isExportable === 'boolean')
      //     columnToToggle.isExportable = action.payload.isExportable;
      //   else {
      //     columnToToggle.isExportable = !columnToToggle.isExportable;
      //   }
      // }
    },
    removeColumn(state: ColumnsState, action: PayloadAction<Pick<ColumnData, 'id'>>) {
      delete state.allColumns[action.payload.id];
    },
    reorderColumns(state: any, action: any) {
      state.columnsOrder = action.payload.columnsOrder;
    },
    toggleHidden(state: ColumnsState, action: PayloadAction<{ id: string }>) {
      const id = action.payload.id;
      const wasHidden = !!state.allColumns[id].isHidden;
      state.allColumns[id].isHidden = !wasHidden;
    },
  },
});

export const columnActions = columnSlice.actions;
