import { Flex, HStack, Select, Text } from '@chakra-ui/react';
import { DOCUMENT_ID } from 'constants/apryse';
import { useSelector } from 'react-redux';
import { getSLDConnections, getSLDFeeders, getSLDPanels } from 'redux/selectors';
import { SLDConnection, SLDFeederElement, SLDPanelElement, State } from 'types/redux';
import { pxToRem } from 'utils';

import { ConnectionModal } from './connection-modal';
import { styles } from './styles';

const SLDInputs = () => {
  const sldPanels = useSelector<State>((state) => getSLDPanels(state, { documentId: DOCUMENT_ID }));
  const sldFeeders = useSelector<State>((state) =>
    getSLDFeeders(state, { documentId: DOCUMENT_ID }),
  );
  const connectionList = useSelector<State>((state) =>
    getSLDConnections(state, { documentId: DOCUMENT_ID }),
  );

  return (
    <Flex direction='column'>
      <ConnectionModal
        sldFeeders={sldFeeders as SLDFeederElement[]}
        sldPanels={sldPanels as SLDPanelElement[]}
      />
      {(connectionList as SLDConnection[])?.map((sldConnection, idx: number) => (
        <HStack
          spacing={pxToRem(11)}
          w='100%'
          mb={pxToRem(8)}
          alignItems='center'
          key={`sldElement-${idx + 1}`}
        >
          <Select
            placeholder='Choose element...'
            sx={styles.select}
            background='#EFEEFF'
            defaultValue={sldConnection.panelOne.backendId}
            isDisabled
          >
            {(sldPanels as SLDPanelElement[])?.map((panelOption, idx: number) => {
              if (panelOption.backendId !== sldConnection.panelTwo.backendId) {
                return (
                  <option key={idx} value={panelOption.backendId}>
                    {panelOption.tag}
                  </option>
                );
              }
            })}
          </Select>
          <Text color='purple.100' fontWeight={500} lineHeight='22px' fontSize='14px'>
            -
          </Text>
          <Select
            placeholder='Tag'
            sx={styles.select}
            w={pxToRem(300)}
            defaultValue={sldConnection.feeder.backendId}
            isDisabled
          >
            {(sldFeeders as SLDFeederElement[])?.map((feederOption, idx: number) => (
              <option key={idx} value={feederOption.backendId}>
                {feederOption.tag}
              </option>
            ))}
          </Select>
          <Text color='purple.100' fontWeight={500} lineHeight='22px' fontSize='14px'>
            -
          </Text>
          <Select
            placeholder='Choose element...'
            sx={styles.select}
            defaultValue={sldConnection.panelTwo.backendId}
            isDisabled
          >
            {(sldPanels as SLDPanelElement[])?.map((panelOption, idx: number) => {
              if (panelOption.backendId !== sldConnection.panelOne.backendId) {
                return (
                  <option key={idx} value={panelOption.backendId}>
                    {panelOption.tag}
                  </option>
                );
              }
            })}
          </Select>
        </HStack>
      ))}
    </Flex>
  );
};

export default SLDInputs;
