import { Column } from 'react-table';

import { Annotation } from './redux';

export type InfoBlockProps = {
  isOpen: boolean;
  handleClose: () => void;
};

export type InfoBlockTabProps = {
  handleClose: () => void;
};

export type ApryseBlockProps = {
  isInfoBlockOpen: boolean;
};

export type TableProps = {
  columns: {
    Header: string;
    accessor: string;
  }[];
  data: readonly object[];
  defaultColumn: Partial<Column<object>> | undefined;
};

export type DropzoneFile = {
  path: string;
  preview: string;
  lastModified: number;
  name: string;
  size: number;
  type: string;
  webkitRelativePath: string;
};

export interface ApryseAnnotation extends Annotation {
  [x: string]: any;

  Id: string;
  pageNumber: number;
  PageNumber?: number;
  style: string;
  dashes: string;
  x: number | never;
  X?: number | never;
  y: number | never;
  Y?: number | never;
  width: number | never;
  Width?: number | never;
  height: number | never;
  Height?: number | never;
  precision?: number;
  scale?: string[][];
}

export interface RectangleAnnotation extends Annotation {
  Id: string;
  pageNumber: number;
  PageNumber?: number;
  style: string;
  dashes: string;
  x: number;
  X?: number | never;
  y: number;
  Y?: number | never;
  width: number;
  Width?: number | never;
  height: number;
  Height?: number | never;
  precision?: number;
  scale?: string[][];
}

export interface DrawerProject {
  id: number;
  name: string;
  updatedAt: number;
  createdAt: number;
  filesMeta: {
    totalFilesCount: number;
    totalFilesSize: number;
  };
}

export type AnnotationPrototypeDrawProps = {
  save: () => void;
  fillStyle: string;
  font: string;
  textAlign: string;
  textBaseline: string;
  fillText: (arg0: string, arg1: number, arg2: number) => void;
  restore: () => void;
};

export enum EAnnotationSubject {
  POLYLINE = 'Polyline',
  RECTANGLE = 'Rectangle',
}

export type TIconProps = { size?: string };

export enum EFixedCachedKeys {
  SETTINGS_FORM_MUTATION = 'settings-form-mutation',
  IMPORT_JSON = 'import-json-mutation',
}

export interface IProjectPageHeaderProps {
  path?: string;
}

export enum SLD_TYPES {
  FEEDER = 'Feeder',
  PANEL = 'Panel/Equip',
}
